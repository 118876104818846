export const providerSavePatientChanges = async (data) => {
  const patient = {
    id: data.id,
    firstName: data.firstName,
    lastName: data.lastName,
    phoneNumber: data.phoneNumber,
    cellNumber: data.cellNumber,
    MRN: data.mrn,
    email: data.email,
    address: data.address,
    city: data.city,
    state: data.state,
    zip: data.zip,
    timeZone: data.timezone,
    bpIMEI: data.bpImei,
    ttBpIMEI: data.transtekBpImei,
    weightIMEI: data.weightImei,
    ttWeightIMEI: data.transtekWeightImei,
    pulseIMEI: data.pulseImei,
    glucoseIMEI: data.glucoseImei,
    patientClinic: data.clinic,
    selectedBpDevice: data.selectedBpDevice,
    selectedWeightDevice: data.selectedWeightDevice,
    deviceNotificationsEnabled: data.deviceNotificationsEnabled,
    providerId: sessionStorage.getItem('providerID'),
  };

  return await fetch('/routes/users/providerSavePatientChanges', {
    method: 'POST',
    body: JSON.stringify(patient),
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return {
        error: 'error',
        status: response.status,
        statusText: response.statusText,
      };
    })
    .catch((error) => {
      console.error(error);
      return { error };
    });
};
