export const registerDeviceUpdates = async (registrationFields) => {
  const { phoneNumber, firstName, lastName, imei } = registrationFields;

  const input = {
    phoneNumber: phoneNumber.trim(),
    firstName: firstName.trim(),
    lastName: lastName.trim(),
    imei,
  };

  return fetch('/routes/device-updates/register', {
    method: 'POST',
    body: JSON.stringify(input),
    headers: { 'Content-Type': 'application/json' },
  }).then((response) => {
    if (response.ok || response.status === 404 || response.status === 409) {
      return response.json();
    }
    return {
      error: 'server-error',
      message: 'There is an error with the IMEI number.',
    };
  });
};
