import { Select, MenuItem } from '@material-ui/core';

export const InfoDropdown = ({ items = [] }) => {
  return (
    <Select
      style={{ marginBottom: '10px' }}
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      disableUnderline
    >
      {items.map((item, index) => (
        <MenuItem style={{ backgroundColor: 'white' }} key={index}>
          {item}
        </MenuItem>
      ))}
    </Select>
  );
};
