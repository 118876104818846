import { useMemo } from 'react';
import moment from 'moment-timezone';
import { MultiLineChart } from '../../common/MultiLineChart.jsx';
import { ThresholdDot } from '../../common/ThresholdDot.jsx';
import { blue } from '../../../common/colors.js';

export const BloodOxygenChart = ({ threshold, chartData = [] }) => {
  const data = useMemo(
    () =>
      chartData
        .map((data) => {
          let taken = '';
          taken = moment(data.time, 'YY/MM/DD,hh:mm:ss±zz').format('MM/DD/YY');
          return {
            date: taken,
            SPO2: data.spo2,
          };
        })
        .reverse(),
    [chartData]
  );
  return (
    <MultiLineChart
      title={'SPO2 Trend'}
      label={'%'}
      lines={[
        {
          key: 'SPO2',
          color: blue,
          dot: () => <ThresholdDot threshold={threshold} />,
        },
      ]}
      data={data}
      domain={[60, 100]}
    />
  );
};

export const HeartRateOximeterChart = ({ threshold, chartData = [] }) => {
  const data = useMemo(
    () =>
      chartData
        .map((data) => {
          let taken = '';
          taken = moment(data.time, 'YY/MM/DD,hh:mm:ss±zz').format('MM/DD/YY');
          return {
            date: taken,
            BPM: data.pr,
          };
        })
        .reverse(),
    [chartData]
  );
  return (
    <MultiLineChart
      title={'Heart Rate Trend - From Oximeter'}
      label={'BPM'}
      lines={[
        {
          key: 'BPM',
          color: blue,
          dot: () => <ThresholdDot threshold={threshold} />,
        },
      ]}
      data={data}
      domain={[40, 220]}
    />
  );
};
