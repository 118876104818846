import { useState, useMemo, useCallback, Fragment } from 'react';
import {
  GridPanelHeader,
  GridPanelWrapper,
  GridPanelContent,
  GridPanelFooter,
  useGridSelector,
  gridColumnDefinitionsSelector,
  gridColumnVisibilityModelSelector,
  useGridApiContext,
  GridSearchIcon,
} from '@mui/x-data-grid';
import {
  TextField,
  Typography,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  Button,
  Switch,
  styled,
  switchClasses,
  Divider,
  IconButton,
  Box,
  Grid,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const GridColumnsPanelRoot = styled('div', {
  name: 'MuiDataGrid',
  slot: 'ColumnsPanel',
  overridesResolver: (_props, styles) => styles.columnsPanel,
})(() => ({
  padding: '8px 0px 8px 8px',
}));

const GridColumnsPanelRowRoot = styled('div', {
  name: 'MuiDataGrid',
  slot: 'ColumnsPanelRow',
  overridesResolver: (_props, styles) => styles.columnsPanelRow,
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '1px 8px 1px 7px',
  [`& .${switchClasses.root}`]: {
    marginRight: theme.spacing(0.5),
  },
}));

const groupedColumns = [
  'Blood Pressure',
  'Weight Scale',
  'Pulse Oximeter',
  'Blood Glucose',
];

export const ExtendedGridColumnsPanel = () => {
  const apiRef = useGridApiContext();
  const [searchValue, setSearchValue] = useState('');
  const [showExtendedFilters, setShowExtendedFilter] = useState(false);
  const handleSearchValueChange = useCallback((e) => {
    setSearchValue(e.target.value);
  }, []);

  const columnVisibilityModel = useGridSelector(
    apiRef,
    gridColumnVisibilityModelSelector
  );

  const columns = useGridSelector(apiRef, gridColumnDefinitionsSelector);

  const toggleColumn = (e) => {
    const { name: field } = e.target;
    apiRef.current.setColumnVisibility(field, e.target.checked);
  };

  const toggleAllColumns = useCallback(
    (checked) => {
      return apiRef.current.setColumnVisibilityModel(
        Object.fromEntries(
          columns
            .filter((col) => col.hideable !== false)
            .map((col) => [col.field, checked])
        )
      );
    },
    [apiRef, columns]
  );

  const allColumnsVisible = useMemo(
    () => Object.values(columnVisibilityModel).every((item) => item === true),
    [columnVisibilityModel]
  );

  const allColumnsHidden = useMemo(
    () => Object.values(columnVisibilityModel).every((item) => item === false),
    [columnVisibilityModel]
  );

  const currentGroupedColumns = useMemo(() => {
    if (!searchValue) {
      return groupedColumns;
    }
    const searchQuery = searchValue.toLowerCase();
    return groupedColumns.filter((col) =>
      col.toLowerCase().includes(searchQuery)
    );
  }, [searchValue]);

  const currentColumns = useMemo(() => {
    if (!searchValue) {
      return columns;
    }

    const searchQuery = searchValue.toLowerCase();
    return columns.filter((col) =>
      col.headerName?.toLowerCase().includes(searchQuery)
    );
  }, [columns, searchValue]);

  return (
    <GridPanelWrapper>
      <GridPanelHeader>
        <GridColumnsPanelRoot>
          <Grid
            container
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Grid item sm={6}>
              <Typography variant="body1">Filters</Typography>
            </Grid>
            <Grid item sm={6}>
              <Box display="flex" justifyContent="flex-end">
                <IconButton
                  sx={{ justifyContent: 'flex-end' }}
                  onClick={() => apiRef.current.hideFilterPanel()}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <GridSearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder="Search"
            value={searchValue}
            onChange={handleSearchValueChange}
            fullWidth
            size="small"
            variant="outlined"
            focused={true}
          />
        </GridColumnsPanelRoot>
      </GridPanelHeader>
      <GridPanelContent>
        <GridColumnsPanelRoot>
          {currentGroupedColumns.includes('Blood Pressure') && (
            <GridColumnsPanelRowRoot>
              <FormControlLabel
                control={<Switch size="small" />}
                label="Blood Pressure"
                checked={
                  columnVisibilityModel.bpThresholdLevel &&
                  columnVisibilityModel.bptaken &&
                  columnVisibilityModel.pulse &&
                  columnVisibilityModel.recentBp
                }
                onChange={(e) => {
                  const checked = e.target.checked;
                  apiRef.current.setColumnVisibilityModel({
                    ...columnVisibilityModel,
                    bpThresholdLevel: checked,
                    bptaken: checked,
                    pulse: checked,
                    recentBp: checked,
                  });
                }}
              />
            </GridColumnsPanelRowRoot>
          )}
          {currentGroupedColumns.includes('Weight Scale') && (
            <GridColumnsPanelRowRoot>
              <FormControlLabel
                control={<Switch size="small" />}
                label="Weight Scale"
                checked={
                  columnVisibilityModel.weight &&
                  columnVisibilityModel.weighttaken &&
                  columnVisibilityModel.recentWs
                }
                onChange={(e) => {
                  const checked = e.target.checked;
                  apiRef.current.setColumnVisibilityModel({
                    ...columnVisibilityModel,
                    weight: checked,
                    weighttaken: checked,
                    recentWs: checked,
                  });
                }}
              />
            </GridColumnsPanelRowRoot>
          )}
          {currentGroupedColumns.includes('Pulse Oximeter') && (
            <GridColumnsPanelRowRoot>
              <FormControlLabel
                control={<Switch size="small" />}
                label="Pulse Oximeter"
                checked={
                  columnVisibilityModel.spo2Date &&
                  columnVisibilityModel.spo2 &&
                  columnVisibilityModel.pulseRate &&
                  columnVisibilityModel.recentPulse
                }
                onChange={(e) => {
                  const checked = e.target.checked;
                  apiRef.current.setColumnVisibilityModel({
                    ...columnVisibilityModel,
                    spo2Date: checked,
                    spo2: checked,
                    pulseRate: checked,
                    recentPulse: checked,
                  });
                }}
              />
            </GridColumnsPanelRowRoot>
          )}
          {currentGroupedColumns.includes('Blood Glucose') && (
            <GridColumnsPanelRowRoot>
              <FormControlLabel
                control={<Switch size="small" />}
                label="Blood Glucose"
                checked={
                  columnVisibilityModel.glucoseDate &&
                  columnVisibilityModel.glucose &&
                  columnVisibilityModel.recentGlucose
                }
                onChange={(e) => {
                  const checked = e.target.checked;
                  apiRef.current.setColumnVisibilityModel({
                    ...columnVisibilityModel,
                    glucose: checked,
                    glucoseDate: checked,
                    recentGlucose: checked,
                  });
                }}
              />
            </GridColumnsPanelRowRoot>
          )}
          {showExtendedFilters && (
            <Fragment>
              <Divider sx={{ marginBottom: '8px' }} />
              {currentColumns.map((column) => {
                if (column.hideable) {
                  return (
                    <GridColumnsPanelRowRoot key={column.field}>
                      <FormControlLabel
                        control={<Switch size="small" />}
                        label={column.headerName}
                        checked={columnVisibilityModel[column.field]}
                        name={column.field}
                        onClick={toggleColumn}
                      />
                    </GridColumnsPanelRowRoot>
                  );
                }
                return null;
              })}
            </Fragment>
          )}
        </GridColumnsPanelRoot>
      </GridPanelContent>
      <GridPanelFooter>
        <GridColumnsPanelRoot>
          <Divider />
          <FormControlLabel
            sx={{
              mt: '0.25em',
              mb: '0.25em',
            }}
            control={
              <Checkbox
                checked={allColumnsVisible}
                indeterminate={!allColumnsVisible && !allColumnsHidden}
                onChange={(e) => toggleAllColumns(e.target.checked)}
              />
            }
            label="Show/Hide All"
          />
          <Button
            size="small"
            onClick={() => setShowExtendedFilter(!showExtendedFilters)}
          >
            {showExtendedFilters ? 'Default Filters' : 'Extend Filters'}
          </Button>
        </GridColumnsPanelRoot>
      </GridPanelFooter>
    </GridPanelWrapper>
  );
};
