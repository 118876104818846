import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useStyles } from './style';

// Returns Lbs or Kgs
export const useWeightUnit = () => {
  const [weightUnit, setWeightUnit] = useState('Lbs'); // Default value is 'Lbs'

  useEffect(() => {
    // Retrieve the weight unit from local storage
    const storedWeightUnit = localStorage.getItem('weightUnit');

    // Set the weight unit to the stored value or 'Lbs' by default
    if (storedWeightUnit) {
      setWeightUnit(storedWeightUnit);
    }
  }, []);

  return { weightUnit, setWeightUnit };
};

export const DisplaySettings = ({ onUpdate }) => {
  const { weightUnit, setWeightUnit } = useWeightUnit();

  const classes = useStyles();

  // Function to handle weight unit change
  const handleWeightUnitChange = (event) => {
    const newUnit = event.target.value;
    setWeightUnit(newUnit);
    // Update local storage with the new unit
    localStorage.setItem('weightUnit', newUnit);
    onUpdate();
  };

  return (
    <Container maxWidth="lg" style={{ marginTop: '-25px' }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <Typography className={classes.heading}>
            <h4>Display Settings</h4>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Typography>
              <h6>Weight Unit</h6>
            </Typography>
            <RadioGroup
              aria-label="weightUnit"
              name="weightUnit"
              value={weightUnit}
              onChange={handleWeightUnitChange}
              row
            >
              <FormControlLabel value="Lbs" control={<Radio />} label="Lbs" />
              <FormControlLabel value="Kgs" control={<Radio />} label="Kgs" />
            </RadioGroup>
          </div>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};
