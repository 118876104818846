import { EndpointDetails } from './EndpointDetails';
import Stack from '@mui/material/Stack';

const NO_DATA_PLACEHOLDER = '—';

export const EndpointList = ({ endpoints, onTest }) => {
  return Boolean(endpoints) && endpoints?.length > 0 ? (
    <Stack spacing={2}>
      {endpoints?.map((endpoint) => (
        <EndpointDetails endpoint={endpoint} onTest={onTest} />
      ))}
    </Stack>
  ) : (
    NO_DATA_PLACEHOLDER
  );
};
