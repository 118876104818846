import { useMemo } from 'react';
import moment from 'moment-timezone';
import { MultiLineChart } from '../../common/MultiLineChart.jsx';
import { ThresholdDot } from '../../common/ThresholdDot.jsx';
import { StageDot } from '../../common/StageDot.jsx';
import { blue, green } from '../../../common/colors.js';

export const BloodPressureChart = ({ timeZone, threshold, chartData = [] }) => {
  const data = useMemo(
    () =>
      chartData
        .map((data) => {
          let taken = '';
          if (moment(data.ts).tz(timeZone)) {
            taken = moment(data.ts).tz(timeZone).format('MM/DD/YY');
          }
          return {
            date: taken,
            Systolic: data.systolic,
            Diastolic: data.diastolic,
          };
        })
        .reverse(),
    [chartData, timeZone]
  );
  return (
    <MultiLineChart
      title={'BP Trend'}
      label={'mmHg'}
      lines={[
        {
          key: 'Systolic',
          color: blue,
          dot: () => <StageDot threshold={threshold?.systolic} />,
        },
        {
          key: 'Diastolic',
          color: green,
          dot: () => <StageDot threshold={threshold?.diastolic} />,
        },
      ]}
      data={data}
      domain={undefined}
    />
  );
};

export const HeartRateChart = ({ timeZone, threshold, chartData }) => {
  const data = useMemo(
    () =>
      chartData
        .map((data) => {
          let taken = '';
          if (moment(data.ts).tz(timeZone)) {
            taken = moment(data.ts).tz(timeZone).format('MM/DD/YY');
          }
          return {
            date: taken,
            Pulse: data.pulse,
          };
        })
        .reverse(),
    [chartData, timeZone]
  );
  return (
    <MultiLineChart
      title={'Heart Rate Trend - From BP'}
      label={'Pulse'}
      lines={[
        {
          key: 'Pulse',
          color: blue,
          dot: () => <ThresholdDot threshold={threshold} />,
        },
      ]}
      data={data}
      domain={undefined}
    />
  );
};
