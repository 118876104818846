import { useState, useMemo, useEffect } from 'react';
import {
  Button,
  Container,
  Grid,
  MenuItem,
  Paper,
  Select,
} from '@material-ui/core';
import moment from 'moment-timezone';
import timeme, { currentPageName, idleTimeoutMs } from 'timeme.js';
import { useStyles } from '../common/style';
import clsx from 'clsx';
import { useWeightUnit } from '../common/DisplaySetings';
import { parseBpMeasures } from './BloodPressure/bloodPressure';
import {
  BloodPressureChart,
  HeartRateChart,
} from './BloodPressure/BloodPressureCharts';
import { BloodPressureGrid } from './BloodPressure/BloodPressureGrid';
import { parsePulseMeasures } from './Pulse/pulseOximeter';
import {
  BloodOxygenChart,
  HeartRateOximeterChart,
} from './Pulse/PulseOximeterCharts';
import { PulseOximeterGrid } from './Pulse/PulseOximeterGrid';
import { parseGlucoseMeasures } from './Glucose/glucose';
import { GlucoseChart } from './Glucose/GlucoseCharts';
import { GlucoseGrid } from './Glucose/GlucoseGrid';
import { parseWeightScaleMeasures } from './WeightScale/weightScale';
import { WeightScaleChart } from './WeightScale/WeightScaleCharts';
import { WeightScaleGrid } from './WeightScale/WeightScaleGrid';
import { DurationButtons } from './DurationButtons';
import { CARDIOWELL } from '../../common/manufacters';
import { EditPatientModal } from '../../patient/EditPatientModal';
import { providerDeletePatient } from '../../patient/providerDeletePatient';
import { providerSavePatientChanges } from '../../patient/providerSavePatientChanges';
import { sendTimerData } from './sendTimerData';

export const PatientDataDashboard = ({
  patient,
  clinic,
  measurements,
  exportPDF,
  removePatientFromList,
  updatePatientInList,
  updateTime,
}) => {
  const { weightUnit } = useWeightUnit();
  const classes = useStyles();
  const [days, setDays] = useState(31);
  const [durationText, setDurationText] = useState('Last 1 month');
  const [tableView, setTableView] = useState('Chart');
  const [showEditPatientModal, setShowEditPatientModal] = useState(false);

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const bpDevice = patient.selectedBpDevice || CARDIOWELL;
  const weightDevice = patient.selectedWeightDevice || CARDIOWELL;
  const threshold = patient.threshold || {};

  const timeZone =
    measurements?.timeZone && measurements.timeZone !== 'local'
      ? measurements.timeZone
      : moment.tz.guess();
  const d = useMemo(() => moment().subtract(days, 'days').toDate(), [days]);

  const deletePatient = async () => {
    const response = await providerDeletePatient(clinic, patient.id);
    if (response.message === 'Success') {
      timeme.stopTimer(`${patient.id}`);
      timeme.resetRecordedPageTime(`${patient.id}`);
      removePatientFromList(patient.id);
    }
    return response;
  };

  const savePatientChanges = async (patient) => {
    const response = await providerSavePatientChanges(patient);
    if (response.message === 'Success') {
      updatePatientInList(response.data);
    }
    return response;
  };

  const {
    arrayBP,
    bpmTableArray,
    highSys,
    highDia,
    lowSys,
    lowDia,
    avgSys,
    avgDia,
  } = useMemo(
    () =>
      parseBpMeasures({
        bpm: measurements?.bpm,
        btMessagesBpm: measurements?.btMessagesBpm,
        ttBpm: measurements?.ttBpm,
        withingsBpm: measurements?.withingsBpm,
        bpDevice,
        timeframe: d,
        timeZone,
      }),
    [measurements, bpDevice, timeZone, d]
  );

  const { arrayWS, wsTableArray, lowWeight, highWeight } = useMemo(
    () =>
      parseWeightScaleMeasures({
        ws: measurements?.ws,
        btMessagesWs: measurements?.btMessagesWs,
        ttWs: measurements?.ttWs,
        weightDevice,
        weightUnit,
        timeframe: d,
        timeZone,
      }),
    [measurements, weightDevice, weightUnit, timeZone, d]
  );

  const { pulseChartArray, pulseTableArray, averageSpo2 } = useMemo(
    () =>
      parsePulseMeasures({
        pulse: measurements?.pulse,
        timeframe: d,
        timeZone,
      }),
    [measurements, timeZone, d]
  );

  const { glucoseChartArray, glucoseTableArray, averageGlucose } = useMemo(
    () =>
      parseGlucoseMeasures({ glucose: measurements?.glucose, timeframe: d }),
    [measurements, d]
  );

  const InfoGrid = ({ info, title }) => (
    <Grid item xs={6} md={3} lg={3}>
      <Paper className={fixedHeightPaper}>
        <Container>
          <h4 style={{ textAlign: 'center', marginTop: '40px' }}>{info}</h4>
          <h5 style={{ textAlign: 'center' }}>{title}</h5>
          <p style={{ textAlign: 'center', marginTop: '60px' }}>
            {durationText}
          </p>
        </Container>
      </Paper>
    </Grid>
  );

  useEffect(() => {
    timeme.startTimer(`${patient.id}`);

    return async () => {
      timeme.stopTimer(`${patient.id}`);
      const timerData = await sendTimerData(patient.id);
      updateTime(timerData);
    };
  }, [patient.id]);

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <div>
        <Container maxWidth="lg" className={classes.container}>
          <h2>
            {patient?.name}
            <Select
              style={{ marginBottom: '10px' }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              disableUnderline
            >
              <MenuItem style={{ backgroundColor: 'white' }}>
                BP IMEI: {patient?.bpIMEI}
              </MenuItem>
              <MenuItem style={{ backgroundColor: 'white' }}>
                Transtek BP IMEI: {patient?.ttBpIMEI}
              </MenuItem>
              <MenuItem style={{ backgroundColor: 'white' }}>
                Weight IMEI: {patient?.weightIMEI}
              </MenuItem>
              <MenuItem style={{ backgroundColor: 'white' }}>
                Pulse Oximeter IMEI: {patient?.pulseIMEI}
              </MenuItem>
              <MenuItem style={{ backgroundColor: 'white' }}>
                Glucose IMEI: {patient?.glucoseIMEI}
              </MenuItem>
              <MenuItem style={{ backgroundColor: 'white' }}>
                MRN: {patient?.mrn}
              </MenuItem>
              <MenuItem style={{ backgroundColor: 'white' }}>
                Email: {patient?.email}
              </MenuItem>
              <MenuItem style={{ backgroundColor: 'white' }}>
                Cell #: {patient?.cellNumber}
              </MenuItem>
            </Select>
            <Button onClick={() => setShowEditPatientModal(true)}>Edit</Button>
            <Button onClick={() => exportPDF()}>Print Report</Button>
          </h2>
          <Grid container spacing={3}>
            <InfoGrid info={`${avgSys}/${avgDia}`} title={'Avg BP'} />
            <InfoGrid info={`${highSys}/${highDia}`} title={'High BP'} />
            <InfoGrid info={`${lowSys}/${lowDia}`} title={'Low BP'} />
            <InfoGrid info={`${arrayBP.length}`} title={'BP Readings'} />
            <InfoGrid info={averageSpo2} title={'Avg SP02'} />
            <InfoGrid info={averageGlucose} title={'Avg Glucose'} />
          </Grid>
          <DurationButtons
            days={days}
            setDays={setDays}
            setDurationText={setDurationText}
          />
          <div style={{ float: 'left', marginTop: '5px' }}>
            {/* <Button>Readings</Button> */}
            {tableView === 'Chart' ? (
              <div>
                <Button style={{ backgroundColor: '#F0F0F0' }}>Chart</Button>
                <Button onClick={() => setTableView('Readings')}>
                  Readings
                </Button>
              </div>
            ) : (
              <div>
                <Button onClick={() => setTableView('Chart')}>Chart</Button>
                <Button style={{ backgroundColor: '#F0F0F0' }}>Readings</Button>
              </div>
            )}
          </div>
        </Container>
        {tableView === 'Chart' ? (
          <div style={{ marginTop: '25px' }}>
            <Container maxWidth="lg" className={classes.container}>
              <Grid item xs={12} lg={12}>
                <Paper className={fixedHeightPaper}>
                  <BloodPressureChart
                    timeZone={timeZone}
                    chartData={arrayBP}
                    threshold={threshold?.bloodPressure}
                  />
                </Paper>
              </Grid>
            </Container>
            <Container maxWidth="lg" className={classes.container}>
              <Grid item xs={12} lg={12}>
                <Paper className={fixedHeightPaper}>
                  <HeartRateChart
                    timeZone={timeZone}
                    chartData={arrayBP}
                    threshold={threshold?.bloodPressure?.pulse}
                  />
                </Paper>
              </Grid>
            </Container>
            <Container maxWidth="lg" className={classes.container}>
              <Grid item xs={12} lg={12}>
                <Paper className={fixedHeightPaper}>
                  <WeightScaleChart
                    chartData={arrayWS}
                    weightUnit={weightUnit}
                    lowWeight={lowWeight}
                    highWeight={highWeight}
                    timeZone={timeZone}
                    threshold={threshold?.weight?.weight}
                  />
                </Paper>
              </Grid>
            </Container>
            <Container maxWidth="lg" className={classes.container}>
              <Grid item xs={12} lg={12}>
                <Paper className={fixedHeightPaper}>
                  <BloodOxygenChart
                    chartData={pulseChartArray}
                    threshold={threshold.pulseOximeter?.spo2}
                  />
                </Paper>
              </Grid>
            </Container>
            <Container maxWidth="lg" className={classes.container}>
              <Grid item xs={12} lg={12}>
                <Paper className={fixedHeightPaper}>
                  <HeartRateOximeterChart
                    chartData={pulseChartArray}
                    threshold={threshold.pulseOximeter?.pulse}
                  />
                </Paper>
              </Grid>
            </Container>
            <Container maxWidth="lg" className={classes.container}>
              <Grid item xs={12} lg={12}>
                <Paper className={fixedHeightPaper}>
                  <GlucoseChart
                    chartData={glucoseChartArray}
                    threshold={threshold.bloodGlucose?.glucose}
                  />
                </Paper>
              </Grid>
            </Container>
          </div>
        ) : (
          <div style={{ marginTop: '25px' }}>
            <Container maxWidth="lg" className={classes.container}>
              <Grid item xs={12} lg={12}>
                <h5>BP</h5>
                <Paper>
                  <BloodPressureGrid gridData={bpmTableArray} />
                </Paper>
              </Grid>
            </Container>
            <Container maxWidth="lg" className={classes.container}>
              <Grid item xs={12} lg={12}>
                <h5>Weight</h5>
                <Paper>
                  <WeightScaleGrid
                    gridData={wsTableArray}
                    weightUnit={weightUnit}
                  />
                </Paper>
              </Grid>
            </Container>
            <Container maxWidth="lg" className={classes.container}>
              <Grid item xs={12} lg={12}>
                <h5>Pulse</h5>
                <Paper>
                  <PulseOximeterGrid gridData={pulseTableArray} />
                </Paper>
              </Grid>
            </Container>
            <Container maxWidth="lg" className={classes.container}>
              <Grid item xs={12} lg={12}>
                <h5>Glucose</h5>
                <Paper>
                  <GlucoseGrid gridData={glucoseTableArray} />
                </Paper>
              </Grid>
            </Container>
          </div>
        )}
      </div>
      <EditPatientModal
        canDelete={true}
        open={showEditPatientModal}
        patientId={patient.id}
        patient={patient}
        canEditClinic={false}
        timezoneRequired={true}
        handleClose={() => setShowEditPatientModal(false)}
        savePatientChanges={savePatientChanges}
        deletePatient={deletePatient}
      />
    </main>
  );
};
