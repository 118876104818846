import { useId, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Dialog } from '../components/common/Dialog';
import { useForm, FormProvider } from 'react-hook-form';
import { DeviceForm } from './DeviceForm';
import { getCustomers } from '../customer/getCustomers';
import { createDevice } from './createDevice';


const CUSTOMERS_LIMIT = 1000;

const DEFAULT_FORM_STATE = {
  identifier: '',
  type: 'bloodPressure',
  customer: null,
  endpoint: null,
  clinic: null,
  billingStart: null,
  billingEnd: null,
  manufacturer: null,
};

const getClinicsByCustomerId = (customers, id) => {
  const customer = customers.find(({ _id }) => _id === id);
  return customer?.clinics || [];
};

const getEndpointsByCustomerId = (customers, id) => {
  const customer = customers.find(({ _id }) => _id === id);
  return customer?.endpoints || [];
};

export const AddDeviceModal = ({ opend, onClose = () => {}, onSave }) => {
  const formId = useId();
  const [serverError, setServerError] = useState('');
  const [isCustomersLoaded, setCustomersLoaded] = useState(false);

  const [customers, setCustomers] = useState([]);
  const [clinics, setClinics] = useState([]);
  const [endpoints, setEndpoints] = useState([]);

  const methods = useForm({
    defaultValues: DEFAULT_FORM_STATE,
  });

  useEffect(() => {
    if (opend) {
      getCustomers({ pageSize: CUSTOMERS_LIMIT }).then(({ customers }) => {
        setCustomers(customers);
        setCustomersLoaded(true);
      });
    }
  }, [opend]);

  const customerId = methods.watch('customer');

  useEffect(() => {
    setClinics(getClinicsByCustomerId(customers, customerId));
    setEndpoints(getEndpointsByCustomerId(customers, customerId));
    methods.setValue('clinic', null);
    methods.setValue('endpoint', null);
  }, [customerId]);

  const closeHandler = () => {
    onClose?.();
    methods.reset();
    setCustomersLoaded(false);
    setServerError('');
  };

  const onSubmit = async (data) => {
    try {
      await createDevice(data);
      onSave?.();
      closeHandler();
    } catch (e) {
      setServerError(e.toString());
    }
  };

  return (
    <Dialog
      open={opend}
      title="Add Device"
      handleClose={closeHandler}
      actions={
        <>
          <Button variant="secondary" onClick={closeHandler}>
            Close
          </Button>
          <Button
            disabled={!isCustomersLoaded || methods.formState.isSubmitting}
            form={formId}
            type="submit"
            variant="primary"
          >
            Submit
          </Button>
        </>
      }
    >
      <FormProvider {...methods}>
        <form id={formId} noValidate onSubmit={methods.handleSubmit(onSubmit)}>
          <DeviceForm
            serverError={serverError}
            isLoading={!isCustomersLoaded}
            customers={customers}
            clinics={clinics}
            endpoints={endpoints}
          />
        </form>
      </FormProvider>
    </Dialog>
  );
};
