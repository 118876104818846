import {
  PatientDataGrid,
  dateTimeColumns,
  commonColumnProps,
} from '../PatientDataGrid';

const getColumns = (weightUnit) => [
  ...dateTimeColumns,
  {
    ...commonColumnProps,
    field: 'weight',
    headerName: `Weight (${weightUnit === 'Lbs' ? 'lbs' : 'kgs'})`,
    type: 'number',
  },
];

export const WeightScaleGrid = ({ gridData = [], weightUnit }) => {
  return (
    <PatientDataGrid gridData={gridData} columns={getColumns(weightUnit)} />
  );
};
