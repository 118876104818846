import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const NO_DATA_PLACEHOLDER = '—';

export const CustomerDetails = ({ customer }) => {
  return (
    <Stack spacing={2}>
      <div>
        <Typography variant="subtitle2">Name</Typography>
        <Typography variant="body">{customer.name}</Typography>
      </div>

      <div>
        <Typography variant="subtitle2">Contact</Typography>
        <Typography variant="body">
          {customer.Contact || NO_DATA_PLACEHOLDER}
        </Typography>
      </div>

      <div>
        <Typography variant="subtitle2">Contact Number</Typography>
        <Typography variant="body">
          {customer.contactNumber || NO_DATA_PLACEHOLDER}
        </Typography>
      </div>

      <div>
        <Typography variant="subtitle2">Email</Typography>
        <Typography variant="body">
          {customer.email || NO_DATA_PLACEHOLDER}
        </Typography>
      </div>

      <div>
        <Typography variant="subtitle2">Address</Typography>
        <Typography variant="body">
          {customer.address || NO_DATA_PLACEHOLDER}
        </Typography>
      </div>
    </Stack>
  );
};
