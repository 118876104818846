import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Card, CardHeader, CardActions, CardContent } from '@mui/material';

export const EndpointDetails = ({ endpoint, onTest }) => {
  return (
    <Card>
      <CardHeader
        sx={{ pb: 0 }}
        title={endpoint?.name}
        titleTypographyProps={{ fontSize: '1rem', fontWeight: 'medium' }}
      />
      <CardContent>
        <Typography variant="subtitle2">url</Typography>
        <Typography variant="body">{endpoint?.url}</Typography>

        <Typography variant="subtitle2">Key Name</Typography>
        <Typography variant="body">{endpoint?.keyName}</Typography>

        <Typography variant="subtitle2">Key Value</Typography>
        <Typography variant="body">{endpoint?.keyValue}</Typography>

        <Typography variant="subtitle2">Forward</Typography>
        <Typography variant="body">{endpoint?.forward}</Typography>
      </CardContent>

      <CardActions>
        <Button
          variant="secondary"
          size="small"
          onClick={() => onTest?.(endpoint)}
        >
          Test Endpoint
        </Button>
      </CardActions>
    </Card>
  );
};
