import { history } from '../../App.js'

export const getDeviceMeasures = async (imei) => {
  return fetch(`/routes/device-updates/${imei}`, {
    withCredentials: true,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => {
      if (response.ok || response.status === 404 || response.status === 409) {
        return response.json();
      }
      if (response.status === 401) {
        history.push(`/devices/${imei}/magic-link`);
      }
      throw Error(response.status);
    })
    .catch((error) => {
      return { error };
    });
};
