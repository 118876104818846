export const updateCustomer = (id, createCustomerRequest) =>
  fetch(`/routes/customers/${id}`, {
    withCredentials: true,
    method: 'PUT',
    body: JSON.stringify({
      ...createCustomerRequest,
      clinics: createCustomerRequest.clinics.map(({ _id }) => _id),
    }),
    headers: { 'Content-Type': 'application/json' },
  }).then(async (response) => {
    if (response.ok) {
      return response.json();
    } else {
      const { message } = await response.json();
      throw Error(message || "Server error");
    }
  });
