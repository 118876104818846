import moment from 'moment-timezone';
import { parseBpMeasures } from '../PatientData/BloodPressure/bloodPressure';
import { TRANSTEK, BODYTRACE } from '../../common/manufacters';

export const parseDeviceMeasures = (data, timeframe) => {
  const { bpm, bpmMessages, manufacturer, timeZone } = data;
  const localTimeZone = moment.tz.guess();
  const timezone = timeZone && timeZone !== 'local' ? timeZone : localTimeZone;

  if (manufacturer === 'Transtek') {
    const { arrayBP, bpmTableArray, avgSys, avgDia, lastReading } = parseBpMeasures({
      ttBpm: bpm,
      bpDevice: TRANSTEK,
      timeframe,
      timeZone,
    });
    let deviceInfo = {};
    if (bpm.length > 0) {
      const { imei, sn, iccid, dataType, modelNumber, deviceId } = bpm[0];
      deviceInfo = {
        imei,
        sn,
        iccid,
        dataType,
        modelNumber,
        deviceId,
      };
    }
    return {
      arrayBP,
      bpmTableArray,
      avgSys,
      avgDia,
      timeZone: timezone,
      deviceInfo,
      lastReading,
    };
  } else {
    const { arrayBP, bpmTableArray, avgSys, avgDia, lastReading } = parseBpMeasures({
      bpm,
      btMessagesBpm: bpmMessages,
      bpDevice: BODYTRACE,
      timeframe,
      timeZone,
    });
    return {
      arrayBP,
      bpmTableArray,
      avgSys,
      avgDia,
      timeZone: timezone,
      deviceInfo: {},
      lastReading,
    };
  }
};
