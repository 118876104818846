import { useEffect, useId, useState } from 'react';
import Button from '@mui/material/Button';
import { Skeleton } from '@mui/material';
import { Select, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { Dialog } from '../../components/common/Dialog';
import { ThresholdsForm } from '../threshold/ThresholdsForm';
import { updatePatientThresholds } from './updatePatientThresholds';
import { useFormStyles } from '../../components/common/style';
import { getPrograms } from '../program/getPrograms';
import { getProgramThresholds } from '../program/getProgramThresholds';
import { setFormThresholds } from '../threshold/setFormThresholds';
import { defaultFormState } from '../threshold/defaultFormState';
import { useWeightUnit } from '../../components/common/DisplaySetings';

export const PatientThresholdsModal = ({
  open,
  patient,
  clinicId,
  onClose = () => {},
  onSave = () => {},
}) => {
  const formId = useId();
  const [serverError, setServerError] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [programs, setPrograms] = useState([]);
  const classes = useFormStyles();
  const { weightUnit } = useWeightUnit();

  const methods = useForm({
    defaultValues: { programId: '', ...defaultFormState },
  });

  const closeHandler = () => {
    onClose?.();
    methods?.reset();
    setServerError('');
    setLoading(true);
  };

  const onSubmit = async (data) => {
    try {
      const res = await updatePatientThresholds(patient.id, data);
      if (res.error) {
        setServerError('Error from server, double check inputs');
        return;
      }
      patient.threshold = res.threshold;
      if (res.programId) {
        patient.programId = res.programId;
      }
      closeHandler();
      onSave?.(patient);
    } catch (e) {
      setServerError('unknown error occured');
    }
  };

  const assignProgramThresholds = async (programId) => {
    const program = await getProgramThresholds(programId);
    if (program && program.threshold) {
      setFormThresholds(methods, program.threshold);
    } else {
      setServerError('Error retreiving program thresholds');
    }
  };

  useEffect(() => {
    if (open && patient) {
      if (patient.threshold) {
        setFormThresholds(methods, patient.threshold);
      }
      if (patient.programId) {
        methods.setValue('programId', patient.programId);
      }
      getPrograms(clinicId).then((data) => {
        setPrograms(data);
        setLoading(false);
      });
    }
  }, [open, patient?.threshold]);

  return (
    <Dialog
      open={open}
      title="Edit Patient Thresholds"
      handleClose={closeHandler}
      actions={
        <>
          <Button variant="secondary" onClick={closeHandler}>
            Close
          </Button>
          <Button
            disabled={methods.formState.isSubmitting}
            form={formId}
            type="submit"
            variant="primary"
          >
            Submit
          </Button>
        </>
      }
      maxWidth="lg"
    >
      <FormProvider {...methods}>
        <form
          className={classes.paper}
          id={formId}
          noValidate
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {isLoading ? (
            <Skeleton
              variant="rounded"
              width="100%"
              height={56}
              sx={{ mt: 2 }}
            />
          ) : (
            <Controller
              name="programId"
              control={methods.control}
              render={({ field: { name, value, onChange } }) => (
                <FormControl
                  className={classes.formControl}
                  style={{ width: '20%' }}
                >
                  <InputLabel>Select Program</InputLabel>
                  <Select
                    name={name}
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                      assignProgramThresholds(e.target.value);
                    }}
                  >
                    {programs.map((program) => (
                      <MenuItem value={program._id} key={program._id}>
                        {program.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          )}
          <br />
          <ThresholdsForm
            isLoading={isLoading}
            serverError={serverError}
            weightUnit={weightUnit}
          />
        </form>
      </FormProvider>
    </Dialog>
  );
};
