const defaultAlertState = {
  upperHigh: {
    value: 0,
    enabled: false,
  },
  upperMedium: {
    value: 0,
    enabled: false,
  },
  lowerHigh: {
    value: 0,
    enabled: false,
  },
  lowerMedium: {
    value: 0,
    enabled: false,
  },
};

const defaultStageState = {
  normal: {
    value: 0,
    enabled: false,
  },
  elevated: {
    value: 0,
    enabled: false,
  },
  stageOne: {
    value: 0,
    enabled: false,
  },
  stageTwo: {
    value: 0,
    enabled: false,
  },
  crisis: {
    value: 0,
    enabled: false,
  },
};

export const defaultFormState = {
  bloodPressure: {
    systolic: defaultStageState,
    diastolic: defaultStageState,
    pulse: defaultAlertState,
  },
  weight: {
    weight: defaultAlertState,
  },
  pulseOximeter: {
    spo2: defaultAlertState,
    pulse: defaultAlertState,
  },
  bloodGlucose: {
    glucose: defaultAlertState,
  },
};
