import { useState, useId } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { deleteCustomer } from './deleteCustomer';

export const DeleteCustomerDialog = ({
  id,
  opend,
  onClose = () => {},
  onDelete,
}) => {
  const [isLoading, setLoading] = useState(false);

  const titleId = useId();
  const descriptionId = useId();

  const deleteHandler = async () => {
    try {
      setLoading(true);
      await deleteCustomer(id);
      onClose?.();
      onDelete?.()
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={opend}
      onClose={onClose}
      aria-labelledby={titleId}
      aria-describedby={descriptionId}
    >
      <DialogTitle id={titleId}>Delete Customer</DialogTitle>
      <DialogContent>
        <DialogContentText id={descriptionId}>
          Are you sure you want to delete this customer?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="primary"
          disabled={isLoading}
          onClick={deleteHandler}
          autoFocus
        >
          Yes, delete it.
        </Button>
      </DialogActions>
    </Dialog>
  );
};
