import {
  useState,
  useCallback,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
} from '@mui/x-data-grid';
import { useDevicessData } from './useDevicessData';
import { DeleteDeviceDialog } from '../DeleteDeviceDialog';
import { EditDeviceModal } from '../EditDeviceModal';
import { DeviceDetailsModal } from '../DeviceDetailsModal';
import { ErrorBoundary } from '../../components/ErrorBoundary';

import { createColumns, defaultColumnVisibilityModel } from './columns';

const PAGE_SIZE = 10;
const getRowId = (row) => row._id;

const Toolbar = () => (
  <GridToolbarContainer>
    <GridToolbarFilterButton variant="primary" />
    <GridToolbarColumnsButton variant="primary" />
  </GridToolbarContainer>
);

export const DevicesGrid = forwardRef((props, ref) => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    defaultColumnVisibilityModel
  );
  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState();
  const [curentEditId, setCurentEditId] = useState(null);
  const [curentDeleteId, setCurentDeleteId] = useState(null);
  const [curentDetailsId, setCurentDetailsId] = useState(null);

  const { isLoading, rows, rowCountState, dataBus } = useDevicessData(
    paginationModel,
    sortModel,
    filterModel
  );

  useImperativeHandle(
    ref,
    () => {
      return {
        update() {
          dataBus.emit('update');
        },
      };
    },
    [dataBus]
  );

  const deleteHandler = useCallback((id) => setCurentDeleteId(id), []);
  const editHandler = useCallback((id) => setCurentEditId(id), []);
  const showDetailsHandler = useCallback((id) => setCurentDetailsId(id), []);

  const columns = useMemo(
    () =>
      createColumns({
        onEdit: editHandler,
        onDelete: deleteHandler,
        onShowDetails: showDetailsHandler,
      }),
    []
  );

  return (
    <ErrorBoundary>
      <DataGrid
        slots={{
          toolbar: Toolbar,
        }}
        sx={{
          '& p': {
            mt: '1em',
            mb: '1em',
          },
          '--DataGrid-overlayHeight': '300px',
        }} // rewrite boostrap styles.
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={setColumnVisibilityModel}
        getRowId={getRowId}
        columns={columns}
        rows={rows || undefined}
        rowCount={rowCountState}
        loading={isLoading}
        pageSizeOptions={[PAGE_SIZE]}
        paginationModel={paginationModel}
        paginationMode="server"
        sortingMode="server"
        filterMode="server"
        onPaginationModelChange={setPaginationModel}
        onSortModelChange={setSortModel}
        onFilterModelChange={setFilterModel}
        disableRowSelectionOnClick
        autoHeight
      />
      {Boolean(curentEditId) && (
        <EditDeviceModal
          opend={Boolean(curentEditId)}
          id={curentEditId}
          onClose={() => setCurentEditId(null)}
          onSave={() => dataBus.emit('update')}
        />
      )}

      <DeleteDeviceDialog
        opend={Boolean(curentDeleteId)}
        id={curentDeleteId}
        onClose={() => setCurentDeleteId(null)}
        onDelete={() => dataBus.emit('update')}
      />

      <DeviceDetailsModal
        opend={Boolean(curentDetailsId)}
        id={curentDetailsId}
        onClose={() => setCurentDetailsId(null)}
      />
    </ErrorBoundary>
  );
});
