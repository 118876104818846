import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, TextField, FormControlLabel, Grid } from '@material-ui/core';
import { get } from 'lodash';

export const InputGridItem = ({
  sign,
  unit,
  enabledName,
  valueName,
  transform = {
    input: (value) => value,
    output: (e) => e,
  },
  xs = 2,
}) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();
  const enabled = watch(enabledName);
  return (
    <Grid item xs={xs}>
      <Controller
        control={control}
        name={enabledName}
        render={({ field: { name, value, onChange } }) => (
          <FormControlLabel
            name={name}
            value={value}
            checked={value}
            onChange={onChange}
            control={<Checkbox />}
            label={sign}
          />
        )}
      />
      <Controller
        control={control}
        name={valueName}
        rules={{
          valueAsNumber: true,
          min: 0,
        }}
        render={({ field: { name, value, onChange } }) => (
          <TextField
            size="small"
            variant="outlined"
            margin="none"
            name={name}
            value={transform.input(value)}
            onChange={(e) => onChange(transform.output(e))}
            label={unit}
            disabled={!enabled}
            style={{ width: '100px' }}
            error={!!get(errors, name)}
            type="number"
            helperText={get(errors, name)?.message}
          />
        )}
      />
    </Grid>
  );
};
