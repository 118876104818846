import { inchesToMeters, lbsToKgs, stlbToKilos } from '../common/conversions';
import { getUnixTime } from 'date-fns';

export const parseForm = (formData) => {
  const {
    birthDate,
    firstName,
    lastName,
    shortName,
    patientId,
    phoneNumber,
    email,
    height,
    weight,
    macAddress,
    gender,
    prefLang,
    timezone,
    heightUnit,
    weightUnit,
    distanceUnit,
    temperatureUnit,
    mailingPref,
  } = formData;

  const unitPref = {
    weight: weightUnit,
    height: heightUnit,
    distance: distanceUnit,
    temperature: temperatureUnit,
  };

  const heightMeasure = {
    value: Math.round(parseFloat(height) * 100),
    unit: -2,
    type: 4,
  };

  if (heightUnit === 7) {
    heightMeasure.value = Math.round(inchesToMeters(height) * 100);
  }

  const weightMeasure = {
    value: Math.round(parseFloat(weight) * 100),
    unit: -2,
    type: 1,
  };

  if (weightUnit === 2) {
    weightMeasure.value = Math.round(lbsToKgs(weight) * 100);
  } else if (weightUnit === 14) {
    weightMeasure.value = Math.round(stlbToKilos(weight) * 100);
  }

  const timeStamp = getUnixTime(birthDate);
  return {
    mailingPref,
    birthDate: timeStamp,
    measures: [heightMeasure, weightMeasure],
    gender,
    prefLang,
    unitPref,
    email,
    timeZone: timezone,
    shortName,
    patientId,
    macAddresses: [macAddress],
    // optional fields
    ...(firstName && { firstName }),
    ...(lastName && { lastName }),
    ...(phoneNumber && { phoneNumber }),
  };
};
