import { useState } from 'react';
import { TestReport } from './TestReport';
import { TestReportList } from './TestReportList';
import Button from '@mui/material/Button';

const LIST_MODE = 'list';
const SELECTED_REPORT_MODE = 'report';

export const TestReportView = ({ customerId, latestTestReport }) => {
  const [selectedReport, setSelectedReport] = useState(null);
  const [mode, setMode] = useState(SELECTED_REPORT_MODE);

  const selectHandler = (report) => {
    setMode(SELECTED_REPORT_MODE);
    setSelectedReport(report);
  };

  const selectLatestHandler = () => {
    setMode(SELECTED_REPORT_MODE);
    setSelectedReport(null);
  };

  return mode === LIST_MODE ? (
    <TestReportList
      customerId={customerId}
      onSelect={selectHandler}
      onSelectLatest={selectLatestHandler}
    />
  ) : (
    <>
      <TestReport testReport={selectedReport || latestTestReport} />
      <Button onClick={() => setMode(LIST_MODE)}>Show All Reports</Button>
    </>
  );
};
