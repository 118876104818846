import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';

export const TestEndpoint = ({ endpoint, request, response, serverError }) => {
  return (
    <Stack spacing={2}>
      <div>
        <Typography variant="subtitle2">Name</Typography>
        <Typography variant="body">{endpoint.name}</Typography>
      </div>

      <div>
        <Typography variant="subtitle2">url</Typography>
        <Typography variant="body">{endpoint.url}</Typography>
      </div>

      <div>
        <Typography variant="subtitle2">Key Name</Typography>
        <Typography variant="body">{endpoint.keyName}</Typography>
      </div>

      <div>
        <Typography variant="subtitle2">Key Value</Typography>
        <Typography variant="body">{endpoint.keyValue}</Typography>
      </div>

      {Boolean(request) ? (
        <Paper variant="outlined" sx={{ p: 1 }}>
          <Stack spacing={1}>
            <Typography variant="subtitle2">Request</Typography>
            <pre>{JSON.stringify(request, null, 2)}</pre>
          </Stack>
        </Paper>
      ) : null}

      {Boolean(response) ? (
        <Paper variant="outlined" sx={{ p: 1 }}>
          <Stack spacing={1}>
            <Typography variant="subtitle2">
              Response: {response?.status ? `${response.status}` : ''}
            </Typography>
            <pre>{JSON.stringify(response?.data, null, 2)}</pre>
          </Stack>
        </Paper>
      ) : null}

      {serverError && (
        <Alert sx={{ mt: 2 }} severity="error">
          {serverError}
        </Alert>
      )}
    </Stack>
  );
};
