import {
  PatientDataGrid,
  dateTimeColumns,
  commonColumnProps,
} from '../PatientDataGrid';

const columns = [
  ...dateTimeColumns,
  {
    ...commonColumnProps,
    field: 'systolic',
    headerName: 'Systolic (mmHg)',
    type: 'number',
  },
  {
    ...commonColumnProps,
    field: 'diastolic',
    headerName: 'Diastolic (mmHg)',
    type: 'number',
  },
  {
    ...commonColumnProps,
    field: 'pulse',
    headerName: 'Pulse (bpm)',
    type: 'number',
  },
];

export const BloodPressureGrid = ({ gridData = [] }) => {
  return <PatientDataGrid gridData={gridData} columns={columns} />;
};
