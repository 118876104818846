import {
  Button,
  Checkbox,
  FormHelperText,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';
import { AlphaNumericRegex, E164PhoneNumberRegex } from '../common/regex';
import { useForm, Controller } from 'react-hook-form';
import { useStyles } from '../components/common/style';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getTimeZone } from './timezones';
import { ControlledSelect } from './ControlledSelect';
import { ControlledTextField } from './ControlledTextField';

export const WithingsSignupForm = ({ onSubmit, patients = [] }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      birthDate: null,
      patient: '',
      firstName: '',
      lastName: '',
      shortName: '',
      patientId: '',
      phoneNumber: '',
      email: '',
      height: '',
      weight: '',
      macAddress: '',
      gender: '', // 0: male, 1: female
      prefLang: '',
      timezone: '',
      heightUnit: 6, // m: 6, in: 7
      weightUnit: 1, // kg: 1, lb: 2, StLb: 14
      distanceUnit: 6, // km: 6, yd: 8
      temperatureUnit: 11, // C: 11, F: 13
      mailingPref: false,
    },
  });
  const classes = useStyles();

  const parsePatient = (id) => {
    const patient = patients.find((p) => p.id === id || p._id === id);
    if (patient) {
      if (patient.name && patient.name.split(' ')?.length > 1) {
        const name = patient.name.split(' ');
        setValue('firstName', name[0]);
        setValue('lastName', name[1]);
      } else {
        setValue('firstName', patient.firstName);
        setValue('lastName', patient.lastName);
      }
      setValue('patientId', id);
      setValue('phoneNumber', patient.phoneNumber);
      setValue('email', patient.email);
      setValue('timezone', getTimeZone(patient.timeZone));
    }
  };

  const heightUnit = watch('heightUnit');
  const weightUnit = watch('weightUnit');

  const patientArray = patients.map((patient) => {
    const name = patient.name || `${patient.firstName} ${patient.lastName}`;
    const id = patient._id || patient.id || '';
    return (
      <MenuItem value={id} key={id || name}>
        {name}
      </MenuItem>
    );
  });

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="patient"
        control={control}
        render={({ field: { name, value, onChange } }) => (
          <FormControl
            className={classes.formControl}
            style={{ marginLeft: '15px', width: '20%' }}
          >
            <InputLabel id="patient-select-label">Select Patient</InputLabel>
            <Select
              labelId="patient-select-label"
              name={name}
              onChange={(e) => {
                onChange(e.target.value);
                parsePatient(e.target.value);
              }}
              value={value}
              style={{ width: '100%' }}
            >
              {patientArray}
            </Select>
          </FormControl>
        )}
      />
      < br />
      <Controller
        name="birthDate"
        control={control}
        rules={{
          required: 'Required field',
        }}
        render={({ field: { name, value, onChange } }) => (
          <FormControl
            className={classes.formControl}
            style={{ marginLeft: '15px', width: '20%' }}
            error={!!errors[name]}
          >
            <DatePicker
              required
              label="Birthdate*"
              name={name}
              value={value}
              onChange={onChange}
              disableFuture
            />
            {errors[name] && (
              <FormHelperText>{errors[name]?.message}</FormHelperText>
            )}
          </FormControl>
        )}
      />
      <br />
      <ControlledTextField
        inputName="firstName"
        label="Patient First Name"
        control={control}
        error={errors['firstName']}
      />
      <ControlledTextField
        inputName="lastName"
        label="Patient Last Name"
        control={control}
        error={errors['lastName']}
      />
      <ControlledTextField
        inputName="shortName"
        label="Short Name (3 characters)"
        control={control}
        error={errors['shortName']}
        required={true}
        rules={{
          required: 'Required field',
          validate: (values) =>
            (AlphaNumericRegex.test(values) && values.length === 3) ||
            'Please have exactly 3 numbers or letters. No special characters',
        }}
      />
      <ControlledTextField
        inputName="patientId"
        label="Patient Id"
        control={control}
        error={errors['patientId']}
        required={true}
        rules={{ required: 'Required field' }}
      />
      <ControlledTextField
        inputName="phoneNumber"
        label="Phone Number (including country code)"
        control={control}
        error={errors['phoneNumber']}
        rules={{
          validate: (values) =>
            values.length === 0 ||
            E164PhoneNumberRegex.test(values) ||
            'Phone number should match the E.164 format:\n[+][country code][area code][number]',
        }}
      />
      <ControlledTextField
        inputName="email"
        label="Patient Email"
        control={control}
        error={errors['email']}
        required={true}
        rules={{ required: 'Required field' }}
      />
      <ControlledTextField
        inputName="height"
        label={`Patient Height (${heightUnit === 7 ? 'inches' : 'meters'})`}
        control={control}
        error={errors['height']}
        required={true}
        rules={{
          required: 'Required field',
          valueAsNumber: true,
          validate: (value) => value > 0 || 'Please input a number',
        }}
      />
      <ControlledTextField
        inputName="weight"
        label={`Patient Weight (${
          weightUnit === 14
            ? 'imp. stone:pound'
            : weightUnit === 2
            ? 'pounds'
            : 'kilograms'
        })`}
        control={control}
        error={errors['weight']}
        required={true}
        rules={{
          required: 'Required field',
          valueAsNumber: true,
          validate: (value) => value > 0 || 'Please input a number',
        }}
      />
      <ControlledTextField
        inputName="macAddress"
        label="Withings Device Mac Address"
        control={control}
        error={errors['macAddress']}
        required={true}
        rules={{ required: 'Required field' }}
      />
      <br />
      <ControlledSelect
        inputName="gender"
        label="Gender"
        control={control}
        error={errors['gender']}
      >
        <MenuItem value={0}>M</MenuItem>
        <MenuItem value={1}>F</MenuItem>
      </ControlledSelect>
      <ControlledSelect
        inputName="prefLang"
        label="Language"
        control={control}
        error={errors['prefLang']}
      >
        <MenuItem value={'en_EN'}>en_EN</MenuItem>
        <MenuItem value={'en_US'}>en_US</MenuItem>
        <MenuItem value={'de_DE'}>de_DE</MenuItem>
        <MenuItem value={'es_ES'}>es_ES</MenuItem>
        <MenuItem value={'fr_FR'}>fr_FR</MenuItem>
        <MenuItem value={'it_IT'}>it_IT</MenuItem>
        <MenuItem value={'ja_JA'}>ja_JA</MenuItem>
        <MenuItem value={'ko_KR'}>ko_KR</MenuItem>
        <MenuItem value={'nl_NL'}>nl_NL</MenuItem>
        <MenuItem value={'pt_PT'}>pt_PT</MenuItem>
        <MenuItem value={'ru_RU'}>ru_RU</MenuItem>
        <MenuItem value={'zh_CN'}>zh_CN</MenuItem>
      </ControlledSelect>
      <ControlledSelect
        inputName="timezone"
        label="Timezone"
        control={control}
        error={errors['timezone']}
      >
        <MenuItem value={'America/Puerto_Rico'}>AST</MenuItem>
        <MenuItem value={'America/New_York'}>EST</MenuItem>
        <MenuItem value={'America/Phoenix'}>CST</MenuItem>
        <MenuItem value={'America/Denver'}>MST</MenuItem>
        <MenuItem value={'America/Los_Angeles'}>PST</MenuItem>
        <MenuItem value={'America/Juneau'}>AKST</MenuItem>
        <MenuItem value={'Asia/Riyadh'}>SAST</MenuItem>
        <MenuItem value={'Asia/Karachi'}>PKT</MenuItem>
        <MenuItem value={'Asia/Dubai'}>GST</MenuItem>
        <MenuItem value={'HST'}>HST</MenuItem>
        <MenuItem value={'UTC'}>UTC</MenuItem>
      </ControlledSelect>
      <ControlledSelect
        inputName="heightUnit"
        label="Height Unit"
        control={control}
        error={errors['heightUnit']}
      >
        <MenuItem value={6}>Meter</MenuItem>
        <MenuItem value={7}>Inch</MenuItem>
      </ControlledSelect>
      <ControlledSelect
        inputName="weightUnit"
        label="Weight Unit"
        control={control}
        error={errors['weightUnit']}
      >
        <MenuItem value={1}>Kilograms</MenuItem>
        <MenuItem value={2}>Pounds</MenuItem>
        <MenuItem value={14}>Imperial Stone</MenuItem>
      </ControlledSelect>
      <ControlledSelect
        inputName="distanceUnit"
        label="Distance Unit"
        control={control}
        error={errors['distanceUnit']}
      >
        <MenuItem value={6}>Kilometer</MenuItem>
        <MenuItem value={8}>Yard</MenuItem>
      </ControlledSelect>
      <ControlledSelect
        inputName="temperatureUnit"
        label="Temperature Unit"
        control={control}
        error={errors['temperatureUnit']}
      >
        <MenuItem value={11}>Celsius</MenuItem>
        <MenuItem value={13}>Fahrenheit</MenuItem>
      </ControlledSelect>
      <br />
      <Controller
        name="mailingPref"
        control={control}
        render={({ field: { name, value, onChange } }) => (
          <FormControl className={classes.formControl}>
            <br />
            <FormControlLabel
              name={name}
              value={value}
              onChange={onChange}
              control={<Checkbox />}
              label="Subscribe Patient to mailing"
            />
          </FormControl>
        )}
      />
      <br />
      <Button
        variant="contained"
        color="primary"
        type="submit"
        className={classes.submit}
        style={{ marginLeft: '15px' }}
      >
        Register Device
      </Button>
    </form>
  );
};
