import { useId, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Dialog } from '../components/common/Dialog';
import { useForm, FormProvider } from 'react-hook-form';
import { CustomerForm } from './CustomerForm/CustomerForm';
import { getClinics } from './getClinics';
import { updateCustomer } from './updateCustomer';
import { getOneCustomer } from './getOneCustomer';
import { TestEndpoint } from './TestEndpoint';
import { testEndpointStatusMessage } from './testEndpointStatusMessage';
import { testEndpointTelemetryMessage } from './testEndpointTelemetryMessage';

const EDIT_MODE = 'edit';
const TEST_MODE = 'test';

const DEFAULT_FORM_STATE = {
  name: '',
  contact: '',
  contactNumber: '',
  email: '',
  address: '',
  clinics: [],
};

const getTitle = ({ mode, testEndpoint }) =>
  mode === EDIT_MODE
    ? 'Edit Customer'
    : `Edit Customer | ${testEndpoint?.name}`;

export const EditCustomerModal = ({
  id,
  opend,
  onClose = () => {},
  onSave,
}) => {
  const formId = useId();
  const [serverError, setServerError] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [clinics, setClinics] = useState([]);
  const [mode, setMode] = useState(EDIT_MODE);
  const [testEndpoint, setTestEndpoint] = useState(null);
  const [testRequest, setTestRequest] = useState(null);
  const [testResponse, setTestResponse] = useState(null);

  const clearTestData = () => {
    setTestEndpoint(null);
    setTestRequest(null);
    setTestResponse(null);
  };

  const methods = useForm({
    defaultValues: DEFAULT_FORM_STATE,
  });

  useEffect(() => {
    if (opend) {
      Promise.all([getClinics(), getOneCustomer(id)]).then(
        ([clinics, customer]) => {
          setClinics(clinics);
          methods.setValue('name', customer.name);
          methods.setValue('contact', customer.contact);
          methods.setValue('contactNumber', customer.contactNumber);
          methods.setValue('email', customer.email);
          methods.setValue('address', customer.address);
          methods.setValue('endpoints', customer.endpoints);

          const clinicsSet = new Set(customer.clinics);
          methods.setValue(
            'clinics',
            clinics.filter(({ _id }) => clinicsSet.has(_id))
          );

          setLoading(false);
        }
      );
    }
  }, [opend]);

  const closeHandler = () => {
    if (mode === EDIT_MODE) {
      onClose?.();
      setLoading(true);
      setServerError('');
      methods.reset();
    } else {
      setMode(EDIT_MODE);
      clearTestData(null);
    }
  };

  const handleSubmit = async (data) => {
    try {
      await updateCustomer(id, data);
      onSave?.();
      closeHandler();
    } catch (e) {
      setServerError(e.message);
    }
  };

  const handleSendStatus = async () => {
    try {
      const { request, response } = await testEndpointStatusMessage({
        endpoint: testEndpoint,
      });
      setTestRequest(request);
      setTestResponse(response);
      setServerError(null)
    } catch (e) {
      setServerError(e.message);
    }
  };

  const handleSendTelemetry = async () => {
    try {
      const { request, response } = await testEndpointTelemetryMessage({
        endpoint: testEndpoint,
      });
      setTestRequest(request);
      setTestResponse(response);
      setServerError(null)
    } catch (e) {
      setServerError(e.message);
    }
  };

  const handleBack = async () => {
    setTestEndpoint(null);
    setMode(EDIT_MODE);
  };

  const openTestEndpoint = (endpoint) => {
    setTestEndpoint(endpoint);
    setMode(TEST_MODE);
  };

  return (
    <Dialog
      open={opend}
      title={getTitle({ mode, testEndpoint })}
      handleClose={closeHandler}
      actions={
        mode === EDIT_MODE ? (
          <>
            <Button variant="secondary" onClick={closeHandler}>
              Close
            </Button>
            <Button
              disabled={isLoading || methods.formState.isSubmitting}
              form={formId}
              type="submit"
              variant="primary"
            >
              Save
            </Button>
          </>
        ) : (
          <>
            <Button onClick={handleBack} variant="primary">
              Back
            </Button>
            <Button onClick={handleSendStatus} variant="primary">
              Send Status
            </Button>
            <Button onClick={handleSendTelemetry} variant="primary">
              Send Telemetry
            </Button>
          </>
        )
      }
    >
      {mode === EDIT_MODE ? (
        <FormProvider {...methods}>
          <form
            id={formId}
            noValidate
            onSubmit={methods.handleSubmit(handleSubmit)}
          >
            <CustomerForm
              isLoading={isLoading}
              clinics={clinics}
              serverError={serverError}
              isClinicsLoaded
              onTestEndpoint={openTestEndpoint}
            />
          </form>
        </FormProvider>
      ) : (
        <TestEndpoint
          endpoint={testEndpoint}
          request={testRequest}
          response={testResponse}
          serverError={serverError}
        />
      )}
    </Dialog>
  );
};
