import { useEffect, useState } from 'react';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import moment from 'moment-timezone';

const PAGE_SIZE = 10;
const PAGE_SIZE_OPTIONS = [10, 50, 100];
const getRowId = (row) => row.id;

const Toolbar = () => (
  <GridToolbarContainer>
    <GridToolbarFilterButton variant="primary" />
  </GridToolbarContainer>
);

export const commonColumnProps = {
  flex: 1,
  aggregable: false,
  groupable: false,
  resizable: false,
  hideable: false,
  align: 'left',
  headerAlign: 'left',
};

export const dateTimeColumns = [
  {
    ...commonColumnProps,
    field: 'date',
    headerName: 'Date',
    type: 'date',
    valueFormatter: (params) => moment(params.value).format('MM/DD/YY'),
  },
  {
    ...commonColumnProps,
    field: 'time',
    headerName: 'Time',
    type: 'string',
  },
];

export const PatientDataGrid = ({
  gridData = [],
  columns = [],
  paginationCallback = () => {},
  sortCallback = () => {},
  filterCallback = () => {},
}) => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState();

  const dataRows = gridData.map((data, index) => ({ id: index, ...data }));

  useEffect(() => {
    paginationCallback(paginationModel);
    sortCallback(sortModel);
    filterCallback(filterModel);
  });

  return (
    <DataGrid
      slots={{
        toolbar: Toolbar,
      }}
      sx={{
        '& p': {
          mt: '1em',
          mb: '1em',
        },
        '--DataGrid-overlayHeight': '300px',
      }}
      rows={dataRows}
      getRowId={getRowId}
      columns={columns}
      pageSizeOptions={PAGE_SIZE_OPTIONS}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      onSortModelChange={setSortModel}
      onFilterModelChange={setFilterModel}
      disableRowSelectionOnClick
      disableColumnSelector
    />
  );
};
