export const sendMagicLink = async (imei) => {
  return fetch(`/routes/device-updates/${imei}/magic-link`, {
    withCredentials: true,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw Error(response.status);
    });
};

export const authWithMagicLinkToken = async (token) => {
  return fetch(`/routes/users/auth/magic-link`, {
    withCredentials: true,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ token, password: 'test' }),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw Error(response.status);
    })
};
