import { Check, NotInterested } from '@material-ui/icons';
import { IconButton, Button, Link } from '@material-ui/core';
import SmsIcon from '@material-ui/icons/Sms';
import EmailIcon from '@material-ui/icons/Email';
import ErrorIcon from '@mui/icons-material/Error';
import CircleIcon from '@mui/icons-material/Circle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  gramsToKgsString,
  gramsToLbsString,
  timestampToReadableDate,
} from '../../common/conversions';
import { green, yellow, orange, red, darkRed } from '../../common/colors';

const commonColumnProps = {
  flex: 1,
  aggregable: false,
  groupable: false,
  resizable: false,
  minWidth: 120,
  align: 'center',
  headerAlign: 'center',
};

const stagePriority = {
  default: 0,
  normal: 1,
  elevated: 2,
  stageOne: 3,
  stageTwo: 4,
  crisis: 5,
};

const stageSortComparator = (val1, val2) =>
  stagePriority[val1] - stagePriority[val2];

const getStageColor = (level) => {
  switch (level) {
    case 'normal':
      return green;
    case 'elevated':
      return yellow;
    case 'stageOne':
      return orange;
    case 'stageTwo':
      return red;
    case 'crisis':
      return darkRed;
    default:
      return 'default';
  }
};

export const getColumns = ({
  weightUnit,
  setPatientDetails,
  sendSMS,
  sendEmail,
  navigatePatient,
}) => {
  return [
    {
      ...commonColumnProps,
      field: 'name',
      headerName: 'Name',
      type: 'string',
      align: 'left',
      renderCell: (params) => (
        <Link
          component="button"
          align="left"
          onClick={() => navigatePatient(params.id)}
        >
          {params.value}
        </Link>
      ),
    },
    {
      ...commonColumnProps,
      field: 'bptaken',
      headerName: 'Last BP Taken',
      type: 'date',
      renderCell: (params) =>
        timestampToReadableDate(params.row.bptaken, params.row.timezone),
    },
    {
      ...commonColumnProps,
      field: 'bpThresholdLevel',
      headerName: 'BP (mmHg)',
      type: 'singleSelect',
      valueOptions: () => {
        return [
          { value: 'normal', label: 'Normal' },
          { value: 'elevated', label: 'Elevated' },
          { value: 'stageOne', label: 'Stage One' },
          { value: 'stageTwo', label: 'Stage Two' },
          { value: 'crisis', label: 'Crisis' },
        ];
      },
      renderCell: ({ row }) => {
        const level = row.bpThresholdLevel;
        const visibility = level === 'default' ? 'hidden' : 'visible';
        return (
          <div>
            {level === 'normal' || level === 'elevated' ? (
              <CircleIcon
                htmlColor={getStageColor(level)}
                sx={{ marginRight: '5px', visibility }}
              />
            ) : (
              <ErrorIcon
                htmlColor={getStageColor(level)}
                sx={{ marginRight: '5px', visibility }}
              />
            )}
            {row.bp}
          </div>
        );
      },
      sortComparator: stageSortComparator,
    },
    {
      ...commonColumnProps,
      field: 'pulse',
      headerName: 'Pulse (BPM)',
      type: 'number',
    },
    {
      ...commonColumnProps,
      field: 'recentBp',
      headerName: 'BP Readings in Last Month',
      type: 'number',
    },
    {
      ...commonColumnProps,
      field: 'weighttaken',
      headerName: 'Last Weight Taken',
      type: 'date',
      renderCell: (params) =>
        timestampToReadableDate(params.row.weighttaken, params.row.timezone),
    },
    {
      ...commonColumnProps,
      field: 'weight',
      headerName: `Weight (${weightUnit === 'Lbs' ? 'lbs' : 'kgs'})`,
      type: 'number',
      valueFormatter: (params) => {
        if (params.value) {
          return weightUnit === 'Lbs'
            ? gramsToLbsString(params.value)
            : gramsToKgsString(params.value);
        }
        return '';
      },
    },
    {
      ...commonColumnProps,
      field: 'recentWs',
      headerName: 'WS Readings in Last Month',
      type: 'number',
    },
    {
      ...commonColumnProps,
      field: 'spo2Date',
      headerName: 'Last SPO2 Taken',
      type: 'date',
      renderCell: (params) =>
        timestampToReadableDate(params.row.spo2Date, params.row.timezone),
    },
    {
      ...commonColumnProps,
      field: 'spo2',
      headerName: 'SPO2 (%O2)',
      type: 'number',
    },
    {
      ...commonColumnProps,
      field: 'pulseRate',
      headerName: 'Pulse (BPM)',
      type: 'number',
    },
    {
      ...commonColumnProps,
      field: 'recentPulse',
      headerName: 'Pulse Readings in Last Month',
      type: 'number',
    },
    {
      ...commonColumnProps,
      field: 'glucoseDate',
      headerName: 'Last Glucose Taken',
      type: 'date',
      renderCell: (params) =>
        timestampToReadableDate(params.row.glucoseDate, params.row.timezone),
    },
    {
      ...commonColumnProps,
      field: 'glucose',
      headerName: 'Glucose (mg/dl)',
      type: 'number',
    },
    {
      ...commonColumnProps,
      field: 'recentGlucose',
      headerName: 'Glucose Readings in Last Month',
      type: 'number',
    },
    {
      ...commonColumnProps,
      field: 'rt',
      headerName: 'RT (30 days)',
      type: 'string',
      valueFormatter: ({ value }) =>
        new Date(Math.round(value * 1000)).toISOString().slice(11, 19),
    },
    {
      ...commonColumnProps,
      field: 'deviceNotificationsEnabled',
      headerName: 'SMS Consent',
      align: 'center',
      type: 'boolean',
      renderCell: (params) =>
        params.row.deviceNotificationsEnabled ? <Check /> : <NotInterested />,
    },
    {
      ...commonColumnProps,
      field: 'notify',
      headerName: 'Notify Patient',
      align: 'center',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      hideable: false,
      renderCell: (params) => {
        const { cellNumber, name, username, email } = params.row;
        return (
          <div>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                sendSMS(cellNumber, name, username);
              }}
            >
              <SmsIcon />
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                sendEmail(email, name, username);
              }}
            >
              <EmailIcon />
            </Button>
          </div>
        );
      },
    },
    {
      ...commonColumnProps,
      field: 'threshold',
      headerName: 'Thresholds',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      hideable: false,
      renderCell: (params) => (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setPatientDetails(params.row.patient);
          }}
        >
          <MoreVertIcon />
        </IconButton>
      ),
    },
  ];
};

export const defaultColumnVisibilityModel = {
  name: true,
  bptaken: true,
  bpThresholdLevel: true,
  pulse: true,
  recentBp: true,
  weighttaken: true,
  weight: true,
  recentWs: true,
  spo2Date: true,
  spo2: true,
  pulseRate: true,
  recentPulse: true,
  glucoseDate: true,
  glucose: true,
  recentGlucose: true,
  deviceNotificationsEnabled: true,
  notify: true,
  threshold: true,
  rt: true,
};
