import queryString from 'query-string';

export const getCustomers = (getCustomersRequest) => {
  return fetch(
    `/routes/customers?${queryString.stringify(getCustomersRequest)}`,
    {
      withCredentials: true,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
  ).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('Request error');
    }
  });
};
