import { useState, useMemo, useEffect } from 'react';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
} from '@mui/x-data-grid';
import { PatientThresholdsModal } from '../../thresholds/patient/PatientThresholdsModal';
import { useWeightUnit } from '../../components/common/DisplaySetings';
import { parsePatients } from './parsePatients';
import { getColumns, defaultColumnVisibilityModel } from './columns';
import { NotificationModal } from '../NotificationModal';
import { notifyEmail } from '../notifyEmail';
import { notifySMS } from '../notfiySMS';
import { ExtendedGridColumnsPanel } from './ExtendedColumnsPanel';

const PAGE_SIZE = 100;
const PAGE_SIZE_OPTIONS = [25, 50, 100];

const Toolbar = () => (
  <GridToolbarContainer>
    <GridToolbarFilterButton variant="primary" />
    <GridToolbarColumnsButton variant="primary" />
  </GridToolbarContainer>
);

export const ProviderDashboardTable = ({
  patients = [],
  clinicId,
  navigatePatient,
  savePatientData,
}) => {
  const { weightUnit } = useWeightUnit();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
  });

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    defaultColumnVisibilityModel
  );
  const [patientDetails, setPatientDetails] = useState(null);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [notificationModalMessage, setNotificationModalMessage] = useState('');

  const handleNotificationModal = (data) => {
    if (data.message === 'Success') {
      setNotificationModalMessage('Your notification has been sent!');
    } else if (data.errors) {
      setNotificationModalMessage('Missing parameters');
    } else {
      setNotificationModalMessage(data.message);
    }
    setShowNotificationModal(true);
  };

  const sendSMS = async (number, name, username) => {
    const data = await notifySMS(number, name, username);
    handleNotificationModal(data);
  };

  const sendEmail = async (email, name, username) => {
    const data = await notifyEmail(email, name, username);
    handleNotificationModal(data);
  };

  const rowData = useMemo(() => parsePatients(patients), [patients]);
  const columns = useMemo(
    () =>
      getColumns({
        weightUnit,
        setPatientDetails,
        sendSMS,
        sendEmail,
        navigatePatient,
      }),
    [weightUnit, navigatePatient]
  );

  useEffect(() => {
    const providerId = sessionStorage.getItem('providerID');
    const localColumnVisibilityModel = localStorage.getItem(
      `${providerId}-columnVisibilityModel`
    );
    if (localColumnVisibilityModel) {
      setColumnVisibilityModel(JSON.parse(localColumnVisibilityModel));
    }
  }, []);

  useEffect(() => {
    const providerId = sessionStorage.getItem('providerID');
    localStorage.setItem(
      `${providerId}-columnVisibilityModel`,
      JSON.stringify(columnVisibilityModel)
    );
  }, [columnVisibilityModel]);

  return (
    <>
      <DataGrid
        slots={{
          toolbar: Toolbar,
          columnsPanel: ExtendedGridColumnsPanel,
        }}
        sx={{
          '& p': {
            mt: '1em',
            mb: '1em',
          },
          '--DataGrid-overlayHeight': '300px',
          // appbar spacer = 64px, container padding top = 32px, container padding bottom = 32px
          maxHeight: 'calc(100vh - 128px)',
        }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={setColumnVisibilityModel}
        rows={rowData}
        getRowId={(row) => row.id}
        getRowHeight={() => 'auto'}
        getEstimatedRowHeight={() => 73}
        loading={!rowData.length}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        columns={columns}
        disableRowSelectionOnClick
      />
      <PatientThresholdsModal
        open={!!patientDetails}
        patient={patientDetails}
        clinicId={clinicId}
        onClose={() => setPatientDetails(null)}
        onSave={(patientData) => savePatientData(patientData)}
      />
      <NotificationModal
        message={notificationModalMessage}
        open={showNotificationModal}
        onClose={() => {
          setShowNotificationModal(false);
          setNotificationModalMessage('');
        }}
      />
    </>
  );
};
