import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Dialog } from '../components/common/Dialog';
import { getDeviceDetails } from './getDeviceDetails';
import Typography from '@mui/material/Typography';
import { manufacturersMap } from './utils/manufacturers';
import { deviceTypesMap } from './utils/deviceTypes';
import Stack from '@mui/material/Stack';
import { getLanguage } from '../locale/getLocale';
import { getCustomerEndpoint } from './utils/endpoints';
import { dateTransform } from './utils/dateTransform';
import range from 'lodash/range';
import Skeleton from '@mui/material/Skeleton';
import { startDeviceTest } from './startDeviceTest';
import { stopDeviceTest } from './stopDeviceTest';

const StartStopTestButton = ({
  onStart,
  onStop,
  isStarted,
  isLoading,
  disabled,
}) => {
  if (isLoading) {
    return <Skeleton width={114} height={37} />;
  }
  return isStarted ? (
    <Button variant="secondary" onClick={onStop} disabled={disabled}>
      Stop Test
    </Button>
  ) : (
    <Button variant="secondary" onClick={onStart} disabled={disabled}>
      Start Test
    </Button>
  );
};

const NO_DATA_PLACEHOLDER = '—';

const formatBillingDate = (value) =>
  Boolean(value)
    ? new Intl.DateTimeFormat(getLanguage()).format(dateTransform.input(value))
    : NO_DATA_PLACEHOLDER;

const fomratMilliseconds = (value) =>
  Boolean(value)
    ? new Intl.DateTimeFormat(getLanguage(), {
        dateStyle: 'short',
        timeStyle: 'medium',
      }).format(value)
    : NO_DATA_PLACEHOLDER;

const formatSeconds = (value) =>
  Boolean(value)
    ? new Intl.DateTimeFormat(getLanguage(), {
        dateStyle: 'short',
        timeStyle: 'medium',
      }).format(value * 1000)
    : NO_DATA_PLACEHOLDER;

const formatCreatedAt = (value) =>
  Boolean(value)
    ? new Intl.DateTimeFormat(getLanguage(), {
        dateStyle: 'short',
        timeStyle: 'medium',
      }).format(dateTransform.input(value))
    : NO_DATA_PLACEHOLDER;

export const DeviceDetailsModal = ({ id, opend, onClose = () => {} }) => {
  const [device, setDevice] = useState();
  const [loading, setLoading] = useState();
  const [isTestButtonLoading, setTestButtonLoading] = useState();

  const update = () => {
    setLoading(true);
    getDeviceDetails(id).then((device) => {
      setDevice(device);
      setLoading(false);
    }).catch((e) => {
      console.error(e)
      setLoading(false);
    })
  };

  useEffect(() => {
    if (opend) {
      update();
    }
  }, [opend]);

  const createToggleTestHandler =
    ({ start, stop }) =>
    async () => {
      setTestButtonLoading(true);
      try {
        if (start) {
          await startDeviceTest(id);
        }
        if (stop) {
          await stopDeviceTest(id);
        }
        update();
      } catch (e) {
        console.error(e);
      } finally {
        setTestButtonLoading(false);
      }
    };

  return (
    <Dialog
      open={opend}
      title="Device Details"
      handleClose={onClose}
      actions={
        <>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <StartStopTestButton
            onStart={createToggleTestHandler({ start: true })}
            onStop={createToggleTestHandler({ stop: true })}
            isLoading={loading}
            disabled={isTestButtonLoading}
            isStarted={Boolean(device?.testStarted)}
          />
        </>
      }
    >
      {loading ? (
        <Stack spacing={2}>
          {range(24).map((value) => (
            <Skeleton key={value} width="100%" height={42} />
          ))}
        </Stack>
      ) : (
        device && (
          <Stack spacing={2}>
            <div>
              <Typography variant="subtitle2">IMEI</Typography>
              <Typography variant="body">{device.imei}</Typography>
            </div>

            <div>
              <Typography variant="subtitle2">Device Id</Typography>
              <Typography variant="body">
                {device.deviceId || NO_DATA_PLACEHOLDER}
              </Typography>
            </div>

            <div>
              <Typography variant="subtitle2">Type</Typography>
              <Typography variant="body">
                {deviceTypesMap.get(device.type || NO_DATA_PLACEHOLDER)}
              </Typography>
            </div>

            <div>
              <Typography variant="subtitle2">Manufacturer</Typography>
              <Typography variant="body">
                {manufacturersMap.get(device.manufacturer) ||
                  NO_DATA_PLACEHOLDER}
              </Typography>
            </div>

            <div>
              <Typography variant="subtitle2">Customer</Typography>
              <Typography variant="body">
                {device.customer?.name || NO_DATA_PLACEHOLDER}
              </Typography>
            </div>

            <div>
              <Typography variant="subtitle2">Clinic</Typography>
              <Typography variant="body">
                {device.clinic?.name || NO_DATA_PLACEHOLDER}
              </Typography>
            </div>

            <div>
              <Typography variant="subtitle2">Endpoint</Typography>
              <Typography variant="body">
                {getCustomerEndpoint(device.customer)(device.endpoint) ||
                  NO_DATA_PLACEHOLDER}
              </Typography>
            </div>

            <div>
              <Typography variant="subtitle2">Billing Start</Typography>
              <Typography variant="body">
                {formatBillingDate(device.billingStart)}
              </Typography>
            </div>

            <div>
              <Typography variant="subtitle2">Billing End</Typography>
              <Typography variant="body">
                {formatBillingDate(device.billingEnd)}
              </Typography>
            </div>

            <div>
              <Typography variant="subtitle2">Added to System</Typography>
              <Typography variant="body">
                {formatCreatedAt(device.createdAt)}
              </Typography>
            </div>

            <div>
              <Typography variant="subtitle2">Model Number</Typography>
              <Typography variant="body">
                {device.modelNumber || NO_DATA_PLACEHOLDER}
              </Typography>
            </div>

            <div>
              <Typography variant="subtitle2">Battery Level</Typography>
              <Typography variant="body">
                {Boolean(device.bat) ? `${device.bat}%` : NO_DATA_PLACEHOLDER}
              </Typography>
            </div>

            <div>
              <Typography variant="subtitle2">Serial Number</Typography>
              <Typography variant="body">
                {device.sn || NO_DATA_PLACEHOLDER}
              </Typography>
            </div>

            <div>
              <Typography variant="subtitle2">SIM ICCD Number</Typography>
              <Typography variant="body">
                {device.iccid || NO_DATA_PLACEHOLDER}
              </Typography>
            </div>

            <div>
              <Typography variant="subtitle2">MCU Version</Typography>
              <Typography variant="body">
                {device.mcuv || NO_DATA_PLACEHOLDER}
              </Typography>
            </div>

            <div>
              <Typography variant="subtitle2">APP Version</Typography>
              <Typography variant="body">
                {device.appv || NO_DATA_PLACEHOLDER}
              </Typography>
            </div>

            <div>
              <Typography variant="subtitle2">Modem Version</Typography>
              <Typography variant="body">
                {device.fv || NO_DATA_PLACEHOLDER}
              </Typography>
            </div>

            <div>
              <Typography variant="subtitle2">Bpm Algorithm Version</Typography>
              <Typography variant="body">
                {device.algv || NO_DATA_PLACEHOLDER}
              </Typography>
            </div>

            <div>
              <Typography variant="subtitle2">Cellular Carrier</Typography>
              <Typography variant="body">
                {device.ops || NO_DATA_PLACEHOLDER}
              </Typography>
            </div>

            <div>
              <Typography variant="subtitle2">
                Type of Cellular Network
              </Typography>
              <Typography variant="body">
                {device.net || NO_DATA_PLACEHOLDER}
              </Typography>
            </div>

            <div>
              <Typography variant="subtitle2">Signal</Typography>
              <Typography variant="body">
                {device.sig || NO_DATA_PLACEHOLDER}
              </Typography>
            </div>

            <div>
              <Typography variant="subtitle2">Chip Temperature</Typography>
              <Typography variant="body">
                {Boolean(device.tp) ? `${device.tp / 10}℃` : NO_DATA_PLACEHOLDER}
              </Typography>
            </div>

            <div>
              <Typography variant="subtitle2">Last Measurement</Typography>
              <Typography variant="body">
                {fomratMilliseconds(device.lastMeasurement)}
              </Typography>
            </div>

            <div>
              <Typography variant="subtitle2">
                Date/Time Measurement Received by Mother Ship
              </Typography>
              <Typography variant="body">
                {fomratMilliseconds(device.receivedByMotherShip)}
              </Typography>
            </div>

            <div>
              <Typography variant="subtitle2">Time Device uploaded the data</Typography>
              <Typography variant="body">
                {fomratMilliseconds(device.uptime)}
              </Typography>
            </div>

            <div>
              <Typography variant="subtitle2">Received By MDMS</Typography>
              <Typography variant="body">
                {fomratMilliseconds(device.receivedByMdms)}
              </Typography>
            </div>

            <div>
              <Typography variant="subtitle2">Time Cardiowell Sent to Customer Endpoint</Typography>
              <Typography variant="body">
                {fomratMilliseconds(device.forwardedAt)}
              </Typography>
            </div>

            <div>
              <Typography variant="subtitle2">Time Zone</Typography>
              <Typography variant="body">
                {device.tz || NO_DATA_PLACEHOLDER}
              </Typography>
            </div>
          </Stack>
        )
      )}
    </Dialog>
  );
};
