import moment from 'moment-timezone';
import {
  gramsToKgsString,
  gramsToLbsString,
} from '../../../common/conversions';
import { TRANSTEK } from '../../../common/manufacters';

const minLbs = 25;
const minKgs = 10;
const maxLbs = 500;
const maxKgs = 250;

export const parseWeightScaleMeasures = ({
  ws = [],
  btMessagesWs = [],
  ttWs = [],
  weightDevice = '',
  weightUnit = '',
  timeframe = 0,
  timeZone,
}) => {
  const arrayWS = [];
  const wsTableArray = [];
  let lowWeight = weightUnit === 'Lbs' ? minLbs : minKgs;
  let highWeight = weightUnit === 'Lbs' ? maxLbs : maxKgs;

  // Weight Scale Chart Array
  if (weightDevice === TRANSTEK) {
    // ttWs.wt = weight in grams
    for (let z = 0; z < ttWs.length; z++) {
      const tempDate = new Date(ttWs[z].ts * 1000);
      if (tempDate >= timeframe) {
        arrayWS.push({
          ts: ttWs[z].ts * 1000,
          weight: ttWs[z].wt,
        });
      }
    }
  } else {
    // ws.weight = weight in grams
    for (let z = 0; z < ws.length; z++) {
      const tempDate = new Date(ws[z]._created_at);
      if (tempDate >= timeframe) {
        arrayWS.push({
          ts: ws[z]._created_at,
          weight: ws[z].weight,
        });
      }
    }
    for (let z = 0; z < btMessagesWs.length; z++) {
      const reading = btMessagesWs[z];
      const tempDate = new Date(reading.createdAt);
      if (tempDate >= timeframe) {
        arrayWS.push({
          ts: reading.createdAt,
          weight: reading.message.values.weight,
        });
      }
    }
    // sort arrayWS
    if (btMessagesWs.length && ws.length) {
      arrayWS.sort((a, b) => {
        if (a.ts < b.ts) {
          return 1;
        }
        if (a.ts > b.ts) {
          return -1;
        }
        return 0;
      });
    }
  }

  // parse Weight Scale Chart array into table array
  for (let s = 0; s < arrayWS.length; s++) {
    // display weight in lbs or kgs
    const weightObj = {
      date: moment(arrayWS[s].ts).tz(timeZone).toDate(),
      time: moment(arrayWS[s].ts).tz(timeZone).format('h:mm A'),
      weight:
        weightUnit === 'Lbs'
          ? gramsToLbsString(arrayWS[s].weight)
          : gramsToKgsString(arrayWS[s].weight),
    };
    if (parseFloat(weightObj.weight) < lowWeight) {
      lowWeight = parseFloat(weightObj.weight);
    }
    if (parseFloat(weightObj.weight) > highWeight) {
      highWeight = parseFloat(weightObj.weight);
    }
    wsTableArray.push(weightObj);
  }

  return { arrayWS, wsTableArray, lowWeight, highWeight };
};
