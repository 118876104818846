import moment from 'moment-timezone';
import { Decimal } from 'decimal.js';

/**
 *
 * @param {string} grams
 * @returns {string}
 */
export const gramsToLbsString = (grams) =>
  (((parseFloat(grams) / 100) * 22046) / 100 / 1000).toFixed(2);

/**
 *
 * @param {string} grams
 * @returns {string}
 */
export const gramsToKgsString = (grams) =>
  (parseFloat(grams) / 1000).toFixed(2);

/**
 *
 * @param {string} pascals
 * @returns {number}
 */
export const pascalsToTorr = (pascals) =>
  Math.round(parseInt(pascals) * 0.007500617);

/**
 *
 * @param {string} inches
 * @returns {number}
 */
export const inchesToMeters = (inches) => parseFloat(inches) * 0.0254;

/**
 *
 * @param {string} lbs
 * @returns {number}
 */
export const lbsToKgs = (lbs) => new Decimal(lbs).times(0.45359237).toNumber();

/**
 *
 * @param {string} kgs
 * @returns {number}
 */
export const kgsToLbs = (kgs) =>
  new Decimal(kgs).dividedBy(0.45359237).toNumber();

/**
 *
 * @param {string} stlb
 * @returns {number}
 */
export const stlbToKilos = (stlb) => parseFloat(stlb) * 6.3503;

/**
 *
 * @param {number | string} unixTs
 * @param {string} timezone
 * @returns {string}
 */
export const timestampToReadableDate = (unixTs, timezone) => {
  const tz = timezone && timezone !== 'local' ? timezone : moment.tz.guess();
  const momentObj = moment(unixTs).tz(tz);
  if (momentObj?.isValid()) {
    return momentObj.format('MM/DD/YY h:mm A');
  }
  return '';
};
