import { useState, useEffect } from 'react';
import { Container, CssBaseline, Box, Typography } from '@material-ui/core';
import cardiowell1 from '../../images/cardiowell1.png';
import { useFormStyles } from '../common/style';
import { Copyright } from '../common/Copyright';
import { DeviceSignupForm } from './DeviceSignupForm';
import { getDeviceStatus } from './getDeviceStatus';
import { registerDeviceUpdates } from './registerDeviceUpdates';
import { history } from '../../App';

const activatedMessage = 'Device has been activated to receive notifications!';
const alreadyActivatedMessage =
  'Device has already been activated to receive notifications';

export const DeviceSignUp = (props) => {
  const imei = props.match.params.imei;
  const classes = useFormStyles();
  const [showForm, setShowForm] = useState(false);
  const [serverMessage, setServerMessage] = useState('');

  const onSubmit = (formData) => {
    setServerMessage('');
    registerDeviceUpdates({ ...formData, imei }).then((data) => {
      if (data.error) {
        setServerMessage(data.message);
      } else {
        setShowForm(false);
        setServerMessage(activatedMessage);
      }
    });
  };

  useEffect(() => {
    getDeviceStatus(imei).then((data) => {
      if (data.activated) {
        setShowForm(false);
        setServerMessage(alreadyActivatedMessage);
        history.push(`/device-updates/dashboard/${imei}`);
      } else {
        setShowForm(true);
        setServerMessage('');
      }
    });
  }, [imei]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img className={classes.image} src={cardiowell1} alt="" />
        <Typography className={classes.title} variant="h5">
          Device Registration
        </Typography>
        <Typography align="center" variant="body1">
          {`Receive updates from your blood pressure device.`}
        </Typography>
        <Typography align="center" variant="body1">
          {`IMEI#: ${imei}`}
        </Typography>
        {showForm && <DeviceSignupForm onSubmit={onSubmit} />}
        {!!serverMessage && (
          <Typography className={classes.title} variant="body2">
            {serverMessage}
          </Typography>
        )}
      </div>
      <Box className={classes.footer} mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};
