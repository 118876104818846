export const AlphaNumericRegex = new RegExp(/^[a-zA-Z0-9]{3}$/);

export const E164PhoneNumberRegex = new RegExp(/^\+[1-9]\d{1,14}$/);

export const PhoneNumberRegex = new RegExp(
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
);

export const password_check = (pass) => {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  if (regex.exec(pass) == null) {
    return false;
  } else {
    return true;
  }
};
