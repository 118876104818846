import { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid, Typography, Divider } from '@material-ui/core';
import { Alert, Skeleton } from '@mui/material';
import { VitalRow } from './VitalRow';
import { getFieldNames } from './getFieldNames';
import { kgsToLbs, lbsToKgs } from '../../common/conversions';
import { StageVitalRow } from './StageVitalRow';
import { getStageFieldNames } from './getStageFieldNames';
import { get } from 'lodash';

const ColumnTitles = [
  'Track Vital',
  'Parameter',
  'Upper Boundary\n(High Alert)',
  'Upper Boundary\n(Medium Alert)',
  'Lower Boundary\n(High Alert)',
  'Lower Boundary\n(Medium Alert)',
];

const GridDivider = () => (
  <Grid container item>
    <Divider
      style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}
    />
  </Grid>
);

const TitleRow = (titles) => {
  return (
    <Fragment>
      {titles.map((title) => (
        <Grid item xs={2} key={title}>
          <Typography style={{ whiteSpace: 'pre-line' }}>{title}</Typography>
        </Grid>
      ))}
    </Fragment>
  );
};

export const ThresholdsForm = ({ isLoading, serverError, weightUnit }) => {
  const { setValue, watch } = useFormContext();
  const allFields = watch();

  const rowControl = (checked, vital, parameter) => {
    const {
      upperHighEnabledField,
      upperMediumEnabledField,
      lowerHighEnabledField,
      lowerMediumEnabledField,
    } = getFieldNames(vital, parameter);
    setValue(upperHighEnabledField, checked);
    setValue(upperMediumEnabledField, checked);
    setValue(lowerHighEnabledField, checked);
    setValue(lowerMediumEnabledField, checked);
  };

  const rowEnabled = (vital, parameter) => {
    const {
      upperHighEnabledField,
      upperMediumEnabledField,
      lowerHighEnabledField,
      lowerMediumEnabledField,
    } = getFieldNames(vital, parameter);
    const upperHighEnabled = get(allFields, upperHighEnabledField);
    const upperMediumEnabled = get(allFields, upperMediumEnabledField);
    const lowerHighEnabled = get(allFields, lowerHighEnabledField);
    const lowerMediumEnabled = get(allFields, lowerMediumEnabledField);

    return (
      upperHighEnabled ||
      upperMediumEnabled ||
      lowerHighEnabled ||
      lowerMediumEnabled
    );
  };

  const rowStageControl = (checked, vital, parameter) => {
    const {
      normalEnabledName,
      elevatedEnabledName,
      stageOneEnabledName,
      stageTwoEnabledName,
      crisisEnabledName,
    } = getStageFieldNames(vital, parameter);
    setValue(normalEnabledName, checked);
    setValue(elevatedEnabledName, checked);
    setValue(stageOneEnabledName, checked);
    setValue(stageTwoEnabledName, checked);
    setValue(crisisEnabledName, checked);
  };

  const rowStageEnabled = (vital, parameter) => {
    const {
      normalEnabledName,
      elevatedEnabledName,
      stageOneEnabledName,
      stageTwoEnabledName,
      crisisEnabledName,
    } = getStageFieldNames(vital, parameter);
    const normalEnabled = get(allFields, normalEnabledName);
    const elevatedEnabled = get(allFields, elevatedEnabledName);
    const stageOneEnabled = get(allFields, stageOneEnabledName);
    const stageTwoEnabled = get(allFields, stageTwoEnabledName);
    const crisisEnabled = get(allFields, crisisEnabledName);

    return (
      normalEnabled ||
      elevatedEnabled ||
      stageOneEnabled ||
      stageTwoEnabled ||
      crisisEnabled
    );
  };

  const bloodPressureEnabled =
    rowStageEnabled('bloodPressure', 'systolic') ||
    rowStageEnabled('bloodPressure', 'diastolic') ||
    rowEnabled('bloodPressure', 'pulse');

  const weightEnabled = rowEnabled('weight', 'weight');

  const pulseOximeterEnabled =
    rowEnabled('pulseOximeter', 'spo2') || rowEnabled('pulseOximeter', 'pulse');

  const bloodGlucoseEnabled = rowEnabled('bloodGlucose', 'glucose');

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton variant="rounded" width="100%" height={56} sx={{ mt: 2 }} />
          <Skeleton variant="rounded" width="100%" height={56} sx={{ mt: 2 }} />
          <Skeleton variant="rounded" width="100%" height={56} sx={{ mt: 2 }} />
          <Skeleton variant="rounded" width="100%" height={56} sx={{ mt: 2 }} />
          <Skeleton variant="rounded" width="100%" height={56} sx={{ mt: 2 }} />
          <Skeleton variant="rounded" width="100%" height={56} sx={{ mt: 2 }} />
          <Skeleton variant="rounded" width="100%" height={56} sx={{ mt: 2 }} />
        </>
      ) : (
        <Grid container spacing={1} alignContent="center">
          <Grid
            container
            item
            alignContent="center"
            alignItems="center"
            direction="row"
            spacing={1}
            xs={12}
          >
            <Grid item xs={2}>
              <Typography style={{ whiteSpace: 'pre-line' }}>
                {'Track Vital'}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ whiteSpace: 'pre-line' }}>
                {'Measure'}
              </Typography>
            </Grid>
            <Grid item xs={true}>
              <Typography style={{ whiteSpace: 'pre-line' }}>
                {'Normal (green)'}
              </Typography>
            </Grid>
            <Grid item xs={true}>
              <Typography style={{ whiteSpace: 'pre-line' }}>
                {'Elevated (yellow)'}
              </Typography>
            </Grid>
            <Grid item xs={true}>
              <Typography style={{ whiteSpace: 'pre-line' }}>
                {'Stage 1 (orange)'}
              </Typography>
            </Grid>
            <Grid item xs={true}>
              <Typography style={{ whiteSpace: 'pre-line' }}>
                {'Stage 2 (red)'}
              </Typography>
            </Grid>
            <Grid item xs={true}>
              <Typography style={{ whiteSpace: 'pre-line' }}>
                {'Crisis (dark red)'}
              </Typography>
            </Grid>
          </Grid>
          <GridDivider />
          <StageVitalRow
            vital="bloodPressure"
            vitalLabel="Blood Pressure"
            parameter="systolic"
            parameterLabel="Systolic"
            unit="mmHg"
            rowEnabled={bloodPressureEnabled}
            rowControl={(checked) => {
              rowStageControl(checked, 'bloodPressure', 'systolic');
              rowStageControl(checked, 'bloodPressure', 'diastolic');
              rowControl(checked, 'bloodPressure', 'pulse');
            }}
          />
          <StageVitalRow
            vital="bloodPressure"
            parameter="diastolic"
            parameterLabel="Diastolic"
            unit="mmHg"
            rowEnabled={true}
          />
          <GridDivider />
          <Grid
            container
            item
            alignContent="center"
            alignItems="center"
            direction="row"
            spacing={3}
            xs={12}
          >
            {TitleRow(ColumnTitles)}
          </Grid>
          <GridDivider />
          <VitalRow
            vital="bloodPressure"
            parameter="pulse"
            parameterLabel="Pulse (BP)"
            unit="bpm"
            rowEnabled={bloodPressureEnabled}
          />
          <VitalRow
            vital="weight"
            vitalLabel="Weight"
            parameter="weight"
            unit={weightUnit === 'Kgs' ? 'kgs' : 'lbs'}
            rowEnabled={weightEnabled}
            rowControl={(checked) => rowControl(checked, 'weight', 'weight')}
            transform={{
              input: (value) =>
                weightUnit === 'Lbs' && value ? kgsToLbs(value) : value,
              output: (e) =>
                weightUnit === 'Lbs' && e.target.value
                  ? lbsToKgs(e.target.value)
                  : e,
            }}
          />
          <VitalRow
            vital="pulseOximeter"
            vitalLabel="Pulse Oximeter"
            parameter="spo2"
            parameterLabel="SpO2"
            unit="%"
            rowEnabled={pulseOximeterEnabled}
            rowControl={(checked) => {
              rowControl(checked, 'pulseOximeter', 'spo2');
              rowControl(checked, 'pulseOximeter', 'pulse');
            }}
          />
          <VitalRow
            vital="pulseOximeter"
            parameter="pulse"
            parameterLabel="Pulse"
            unit="bpm"
          />
          <VitalRow
            vital="bloodGlucose"
            vitalLabel="Blood Glucose"
            parameter="glucose"
            parameterLabel="Glucose"
            unit="mg/dl"
            rowEnabled={bloodGlucoseEnabled}
            rowControl={(checked) =>
              rowControl(checked, 'bloodGlucose', 'glucose')
            }
          />
        </Grid>
      )}

      {serverError && <Alert severity="error">{serverError}</Alert>}
    </>
  );
};
