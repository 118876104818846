import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from '@material-ui/core';

export const PatientTextField = ({
  name,
  label,
  rules = {},
  disabled = false,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { name, value, onChange } }) => (
        <TextField
          variant="outlined"
          margin="normal"
          required
          style={{ marginLeft: '15px', width: '45%' }}
          label={label}
          name={name}
          value={value}
          autoFocus
          onChange={onChange}
          error={!!errors[name]}
          helperText={errors[name]?.message}
          disabled={disabled}
        />
      )}
    />
  );
};
