export const deleteCustomer = (id) =>
  fetch(`/routes/customers/${id}`, {
    withCredentials: true,
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  }).then((response) => {
    if (response.ok) {
      return response;
    } else {
      throw Error('server error');
    }
  });
