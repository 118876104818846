import { useEffect, useState } from 'react';
import {
  Box,
  CssBaseline,
  AppBar,
  Typography,
  Toolbar,
  CircularProgress,
} from '@material-ui/core';
import { Copyright } from '../common/Copyright';
import { getDeviceMeasures } from './getDeviceMeasures';
import { useStyles } from '../common/style';
import cardiowell2 from '../../images/cardiowell2.png';
import { DeviceReadingsDisplay } from './DeviceReadingsDisplay';

const ErrorDisplay = ({ message }) => {
  const classes = useStyles();
  return (
    <Box className={classes.centered}>
      <Typography>{message}</Typography>
    </Box>
  );
};

const LoadingDisplay = () => {
  const classes = useStyles();
  return (
    <Box className={classes.centered}>
      <CircularProgress />
    </Box>
  );
};

export const DeviceDashboard = (props) => {
  const [display, setDisplay] = useState('loading');
  const [deviceData, setDeviceData] = useState([]);
  const [serverResponse, setServerResponse] = useState('');

  const imei = props.match.params.imei;
  const classes = useStyles();

  useEffect(() => {
    // TODO: subscribe to device updates
    getDeviceMeasures(imei).then((response) => {
      if (response.error) {
        setDisplay('error');
        if (response.message) {
          setServerResponse(response.message);
        } else {
          setServerResponse('There is an error with this IMEI number');
        }
      } else {
        setDisplay('data');
        setDeviceData(response);
      }
    });
  }, [imei]);

  return (
    <main className={classes.content}>
      <CssBaseline />
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Device Readings
          </Typography>
          <img
            style={{ width: '125px', height: '25px' }}
            src={cardiowell2}
            alt="Default"
          />
        </Toolbar>
      </AppBar>
      <div className={classes.appBarSpacer} />
      {display === 'loading' && <LoadingDisplay />}
      {display === 'data' && (
        <DeviceReadingsDisplay imei={imei} deviceData={deviceData} />
      )}
      {display === 'error' && <ErrorDisplay message={serverResponse} />}
      <Box mt={8}>
        <Copyright />
      </Box>
    </main>
  );
};
