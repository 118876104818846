import {
  PatientDataGrid,
  commonColumnProps,
  dateTimeColumns,
} from '../PatientDataGrid';

const columns = [
  ...dateTimeColumns,
  {
    ...commonColumnProps,
    field: 'data',
    headerName: 'Blood Glucose (mg/dl)',
    type: 'number',
  },
];

export const GlucoseGrid = ({ gridData = [] }) => {
  return <PatientDataGrid gridData={gridData} columns={columns} />;
};
