import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { SidebarList } from './listItems';
import Button from '@material-ui/core/Button';
import cardiowell2 from '../images/cardiowell2.png';
import Modal from 'react-bootstrap/Modal';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Check, NotInterested } from '@material-ui/icons';
import { useStyles } from './common/style';
import { AddProvider } from './common/AddProvider';
import { AddPatientModal } from '../patient/AddPatientModal';
import { RegisterWithingsDevices } from '../withings/Withings';
import { AddCustomerModal } from '../customer/AddCustomerModal';
import { CustomersGrid } from '../customer/CustomersGrid/CustomersGrid';
import { DevicesGrid } from "../device/DevicesGrid/DevicesGrid"
import { AddDeviceModal } from '../device/AddDeviceModal';
import { CARDIOWELL, TRANSTEK, WITHINGS } from '../common/manufacters';
import { EditPatientModal } from '../patient/EditPatientModal';
import { adminSavePatientChanges } from '../patient/adminSavePatientChanges';
import { adminDeletePatient } from '../patient/adminDeletePatient';

const DEVICES_GRID = 'devices';
const CUSTOMERS_GRID = 'customers';
const CLINICS_GRID = 'clinics';
const PROVIDERS_GRID = 'providers';
const PATIENTS_GRID = 'patients';

const $ = require('jquery');
$.DataTable = require('datatables.net');

export default function AdminDashboard(props) {
  if (sessionStorage.getItem('user') !== 'admin') {
    props.history.push('/');
  }

  const addCustomerModalRef = useRef()
  const addDeviceModalRef = useRef()
  const classes = useStyles();
  const [currentGrid, setCurrentGrid] = React.useState(CLINICS_GRID);
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [mainDashboard, setMainDashboard] = React.useState(true)
  const [registerDevicesDashboard, setRegisterDevicesDashboard] = React.useState(false)

  // Selected patient states
  const [selectedClinicName, setSelectedClinicName] = React.useState('')
  const [selectedClinicAddress, setSelectedClinicAddress] = React.useState('')
  const [selectedClinicPhoneNumber, setSelectedClinicPhoneNumber] = React.useState('')
  const [selectedClinicMainContact, setSelectedClinicMainContact] = React.useState('')
  const [selectedClinicID, setSelectedClinicID] = React.useState('')
  const [selectedProviderUsername, setSelectedProviderUsername] = React.useState('')
  const [selectedProviderFirstName, setSelectedProviderFirstName] = React.useState('')
  const [selectedProviderLastName, setSelectedProviderLastName] = React.useState('')
  const [selectedProviderPhoneNumber, setSelectedProviderPhoneNumber] = React.useState('')
  const [selectedProviderEmail, setSelectedProviderEmail] = React.useState('')
  const [selectedProviderClinic, setSelectedProviderClinic] = React.useState('')
  const [selectedProviderID, setSelectedProviderID] = React.useState('')
  const [selectedPatientID, setSelectedPatientID] = React.useState('')
  const [selectedPatient, setSelectedPatient] = React.useState({});
 
  const [editClinic, setEditClinic] = React.useState(false)
  const [editProvider, setEditProvider] = React.useState(false)
  const [editPatient, setEditPatient] = React.useState(false)
  const [clinics, setClinics] = React.useState([]);
  const [providers, setProviders] = React.useState([]);
  const [patients, setPatients] = React.useState([]);
  const [adminID, setAdminID] = React.useState(props.location.state.adminID);
  const [isAddCustomerModalOpend, setAddCustomerModalOpend] = React.useState(false);
  const [isAddDeviceModalOpend, setAddDeviceModalOpend] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [practiceName, setPracticeName] = React.useState('');
  const [practiceAddress, setPracticeAddress] = React.useState('');
  const [practicePhoneNumber, setPracticePhoneNumber] = React.useState('');
  const [practiceMainContact, setPracticeMainContact] = React.useState('');

  const [serverMessage1, setServerMessage1] = React.useState('');
  const [serverMessage2, setServerMessage2] = React.useState('');
  const [serverMessage3, setServerMessage3] = React.useState('');
  const [serverMessage4, setServerMessage4] = React.useState('');
  const [fileChosen, setFileChosen] = React.useState(false)
  const [el, setEl] = React.useState('');
  const [el2, setEl2] = React.useState('');
  const [el3, setEl3] = React.useState('');
  const [logo, setLogo] = React.useState('');
  const [selectedImage, setSelectedImage] = React.useState(null);

  function handleClinicClick(name, address, clinicPhoneNumber, mainContact, id) {
    setSelectedClinicName(name);
    setSelectedClinicAddress(address);
    setSelectedClinicPhoneNumber(clinicPhoneNumber);
    setSelectedClinicMainContact(mainContact);
    setSelectedClinicID(id);
    setEditClinic(true)
  }

  const clinicRows = [];
  clinics.forEach((singleClinic) => {
    const name = singleClinic.name
    const address = singleClinic.address
    const clinicPhoneNumber = singleClinic.phoneNumber
    const mainContact = singleClinic.mainContact
    const clinicRow = {
      name: name,
      address: address,
      phoneNumber: clinicPhoneNumber,
      mainContact: mainContact,
      clickEvent: () => {
        handleClinicClick(name, address, clinicPhoneNumber, mainContact, singleClinic._id)
      }
    }
    clinicRows.push(clinicRow)
  })

  const providerRows = [];
  providers.forEach((singleProvider) => {
    const username = singleProvider.username
    const provfirstName = singleProvider.firstName
    const provlastName = singleProvider.lastName
    const provphoneNumber = singleProvider.phoneNumber
    const provemail = singleProvider.email
    const provclinic = singleProvider.clinic
    const providerRow = {
      username: username,
      firstName: provfirstName,
      lastName: provlastName,
      phoneNumber: provphoneNumber,
      email: provemail,
      clinic: provclinic,
      clickEvent: () => {
        setSelectedProviderUsername(username)
        setSelectedProviderFirstName(provfirstName)
        setSelectedProviderLastName(provlastName)
        setSelectedProviderPhoneNumber(provphoneNumber)
        setSelectedProviderEmail(provemail)
        setSelectedProviderClinic(provclinic)
        setSelectedProviderID(singleProvider._id)
        setEditProvider(true)
      }
    }
    providerRows.push(providerRow)
  })

  const patientRows = [];
  patients.forEach((singlePatient) => {
    const {
      _id: patientId,
      firstName,
      lastName,
      phoneNumber,
      cellNumber,
      MRN,
      email,
      address,
      city,
      state,
      zip,
      timeZone,
      clinic,
      bpIMEI = "",
      ttBpIMEI = "",
      weightIMEI = "",
      ttWeightIMEI = "",
      pulseIMEI = "",
      glucoseIMEI = "",
      withingsBpDevices = [],
      selectedBpDevice = CARDIOWELL,
      selectedWeightDevice = CARDIOWELL,
      deviceNotificationsEnabled,
    } = singlePatient
    const selectedBpIMEI 
      = selectedBpDevice === TRANSTEK 
      ? ttBpIMEI 
      : (selectedBpDevice === WITHINGS 
        ? withingsBpDevices?.deviceIds
        : bpIMEI
      )
    const selectedWeightIMEI 
      = selectedWeightDevice === TRANSTEK
      ? ttWeightIMEI
      : weightIMEI

    const patientRow = {
      firstName,
      lastName,
      phoneNumber,
      cellNumber,
      MRN,
      email,
      address,
      city,
      state,
      zip,
      timeZone,
      selectedBpIMEI,
      selectedWeightIMEI,
      pulseIMEI,
      glucoseIMEI,
      clinic,
      selectedBpDevice,
      selectedWeightDevice,
      deviceNotificationsEnabled,
      clickEvent: () => {
        setSelectedPatientID(patientId)
        setSelectedPatient(singlePatient)
        setEditPatient(true)
      }
    }
    patientRows.push(patientRow)
  })

  function deleteClinic() {
    var inputValue = {
      id: selectedClinicID,
      adminID: adminID
    }
    fetch('/routes/users/deleteClinic', {
      method: 'POST',
      body: JSON.stringify(inputValue),
      headers: { "Content-Type": "application/json" }
    }).then((response) => {
      if (response.status !== 201) {
        return setServerMessage1("There has been an error. Please try again.")
      } else {
        return response.json()
      }
    }).then((data) => {
      if (data.message === "Success") {
        const updatedClinics = clinics.filter((clinic) => clinic._id !== data.clinic._id)
        setClinics(updatedClinics);
        setEditClinic(false);
        setServerMessage1("");
      }
    }).catch((error) => {
      console.error(error);
      setServerMessage1("There has been an error. Please try again.")
    });

  }

  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    setSelectedImage(imageFile);
  };

  function saveClinicChanges() {
    var inputValue = {
      id: selectedClinicID,
      name: selectedClinicName,
      address: selectedClinicAddress,
      phoneNumber: selectedClinicPhoneNumber,
      mainContact: selectedClinicMainContact,
      adminID: adminID
    }
    fetch('/routes/users/saveClinicChanges', {
      method: 'POST',
      body: JSON.stringify(inputValue),
      headers: { "Content-Type": "application/json" }
    }).then((response) => {
      if (response.status !== 201) {
        return setServerMessage1("There has been an error. Please try again.")
      } else {
        return response.json()
      }
    }).then((data) => {
      if (data.message === "Success") {
        const index = clinics.findIndex((clinic) => clinic._id === data.clinic._id);
        clinics[index] = data.clinic;
        setClinics([...clinics]);
        setEditClinic(false);
        setServerMessage1(false);
      }

    }).catch((error) => {
      console.error(error);
      setServerMessage1("There has been an error. Please try again.")
    });

    //Save the image for the clinic
    uploadClinicImage();

  }

  const uploadClinicImage = async () => {
    if (selectedImage) {
      try {
        await fetch(`/routes/users/upload-clinic-image/${selectedClinicID}`, {
          method: 'POST',
          body: selectedImage,
        });
      } catch (error) {
        console.error('Error uploading the image:', error);
      }
      setSelectedImage(null);
    }
  }

  function deleteProvider() {
    var inputValue = {
      id: selectedProviderID,
      adminID: adminID
    }
    fetch('/routes/users/deleteProvider', {
      method: 'POST',
      body: JSON.stringify(inputValue),
      headers: { "Content-Type": "application/json" }
    }).then((response) => {
      if (response.status !== 201) {
        return setServerMessage2("There has been an error. Please try again.")
      } else {
        return response.json()
      }
    }).then((data) => {
      if (data.message === "Success") {
        const updatedProviders = providers.filter((provider) => provider._id !== data.provider._id);
        setProviders(updatedProviders);
        setEditProvider(false);
        setServerMessage2("");
      }
    })
      .catch((error) => {
        console.error(error);
        setServerMessage2("There has been an error. Please try again.")
      });
  }

  function saveProviderChanges() {
    var inputValue = {
      id: selectedProviderID,
      username: selectedProviderUsername,
      firstName: selectedProviderFirstName,
      lastName: selectedProviderLastName,
      phoneNumber: selectedProviderPhoneNumber,
      email: selectedProviderEmail,
      clinic: selectedProviderClinic,
      adminID: adminID
    }
    fetch('/routes/users/saveProviderChanges', {
      method: 'POST',
      body: JSON.stringify(inputValue),
      headers: { "Content-Type": "application/json" }
    }).then((response) => {
      if (response.status !== 201) {
        return setServerMessage2("There has been an error. Please try again.")
      } else {
        return response.json()
      }
    }).then((data) => {
      if (data.message === "Success") {
        const index = providers.findIndex((provider) => provider._id === data.provider._id);
        providers[index] = data.provider;
        setProviders([...providers]);
        setEditProvider(false);
        setServerMessage2("");
      }
    })
      .catch((error) => {
        console.error(error);
        setServerMessage2("There has been an error. Please try again.")
      });
  }

  function deletePatient() {
    adminDeletePatient(selectedPatientID, adminID).then((data) => {
      if (data.message === "Success") {
        const updatedPatients = patients.filter((patient) => patient._id !== data.patient._id);
        setPatients(updatedPatients);
        setEditPatient(false);
        setServerMessage3(false);
      }
    })
  }

  const savePatientChanges = (patient) => {
    adminSavePatientChanges(patient, adminID).then((data) => {
      if (data.message === "Success") {
        const index = patients.findIndex((patient) => patient._id === data.patient._id);
        patients[index] = data.patient;
        setPatients([...patients]);
        setEditPatient(false);
        setServerMessage3(false);
      }
    })
  }

  const renderClinics = (clinic, setClinic) => {
    return (
      <FormControl className={classes.formControl}>
        <br></br>
        <InputLabel id="clinic-input-label">Clinic</InputLabel>
        <Select
          labelId="clinic-select-label"
          id="clinic-select"
          onChange={(event) => setClinic(event.target.value)}
          value={clinic}
        >
          {clinics.map(clinic => <MenuItem value={clinic.name}>{clinic.name}</MenuItem>)}


        </Select>
      </FormControl>
    );
  }

  const openAddDeviceModalhandler = () => {
    setAddDeviceModalOpend(true)
  }

  const closeAddDeviceModalhandler = () => {
    setAddDeviceModalOpend(false)
  }

  const openAddCustomerModalhandler = () => {
    setAddCustomerModalOpend(true)
  }

  const closeAddCustomerModalhandler = () => {
    setAddCustomerModalOpend(false)
  }

  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleOpen2 = () => {
    setOpen2(true);
  };
  const handleOpen3 = () => {
    setOpen3(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };

  function addClinic() {
    var inputValue = {
      practiceName: practiceName,
      practiceAddress: practiceAddress,
      practicePhoneNumber: practicePhoneNumber,
      practiceMainContact: practiceMainContact,
      logo: logo,
      adminID: adminID,
    }
    fetch('/routes/users/addClinic', {
      withCredentials: true,
      method: 'POST',
      body: JSON.stringify(inputValue),
      headers: { "Content-Type": "application/json" }
    }).then((response) => {
      if (response.status !== 201) {
        return setServerMessage4("There has been an error. Please try again.")
      } else {
        return response.json()
      }
    }).then((data) => {
      if (data.message === "Success") {
        clinics.push(data.clinic);
        setClinics([...clinics]);
        setServerMessage4("");
        handleClose1();
      }
    })
      .catch((error) => {
        setServerMessage4("There has been an error. Please try again.")
        console.error(error);
      });

    //Save the image for the clinic
    uploadClinicImage();
  }

  $(el).DataTable({
    'retrieve': true,
    'lengthMenu': [[10, 100, -1], [10, 100, "All"]],
    'pageLength': 10
  })
  $(el2).DataTable({
    'retrieve': true,
    'lengthMenu': [[10, 100, -1], [10, 100, "All"]],
    'pageLength': 10
  })
  $(el3).DataTable({
    'retrieve': true,
    'lengthMenu': [[10, 100, -1], [10, 100, "All"]],
    'pageLength': 10
  })

  useEffect(() => {
    fetch("/routes/users/getAdminData")
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "Success") {
          setClinics(data.clinics)
          setProviders(data.providers)
          setPatients(data.patients)
        }
      })
      .catch((error) => {
        console.error("Error fetching admin data:", error)
      })
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            Admin Dashboard
          </Typography>
          <img style={{ width: '125px' }} src={cardiowell2}></img>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <SidebarList 
          setMainDashboard={setMainDashboard} 
          setRegisterDevicesDashboard={setRegisterDevicesDashboard}
        />
      </Drawer>
      {mainDashboard && <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>

          <Modal style={{ marginTop: '50px', marginLeft: '75px', width: '75%' }} show={editClinic} onHide={() => setEditClinic(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Clinic</Modal.Title>
            </Modal.Header>
            <Modal.Body><form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                style={{ marginLeft: '15px', width: '40%' }}
                id="name"
                label="Practice Name"
                name="name"
                autoFocus
                value={selectedClinicName}
                onChange={(event) => setSelectedClinicName(event.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                style={{ marginLeft: '15px', width: '40%' }}
                name="address"
                label="Street Address"
                id="address"
                value={selectedClinicAddress}
                onChange={(event) => setSelectedClinicAddress(event.target.value)}
              />

              <TextField
                variant="outlined"
                margin="normal"
                required
                style={{ marginLeft: '15px', width: '40%' }}
                name="number"
                label="Phone Number"
                id="number"
                value={selectedClinicPhoneNumber}
                onChange={(event) => setSelectedClinicPhoneNumber(event.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                style={{ marginLeft: '15px', width: '40%' }}
                name="contact"
                label="Main Contact"
                id="contact"
                value={selectedClinicMainContact}
                onChange={(event) => setSelectedClinicMainContact(event.target.value)}
              />
              <div style={{ height: '20px' }}></div>
              <p>Add Logo:</p>
              <input type="file" accept="image/*" onChange={handleImageChange} />

              <p>{serverMessage1}</p>
            </form></Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setEditClinic(false)}>
                Close
              </Button>
              <Button onClick={() => deleteClinic()}>Delete</Button>
              <Button variant="primary" onClick={saveClinicChanges}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal style={{ marginTop: '50px', marginLeft: '75px', width: '75%' }} show={editProvider} onHide={() => setEditProvider(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Provider</Modal.Title>
            </Modal.Header>
            <Modal.Body><form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                style={{ marginLeft: '15px', width: '40%' }}
                id="name"
                label="First Name"
                name="name"
                autoFocus
                value={selectedProviderFirstName}
                onChange={(event) => setSelectedProviderFirstName(event.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                style={{ marginLeft: '15px', width: '40%' }}
                name="name"
                label="Last Name"
                id="name"
                value={selectedProviderLastName}
                onChange={(event) => setSelectedProviderLastName(event.target.value)}
              />

              <TextField
                variant="outlined"
                margin="normal"
                required
                style={{ marginLeft: '15px', width: '40%' }}
                id="email"
                label="Email Address"
                name="email"
                autoFocus
                value={selectedProviderEmail}
                onChange={(event) => setSelectedProviderEmail(event.target.value)}

              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                style={{ marginLeft: '15px', width: '40%' }}
                name="number"
                label="Phone Number"
                id="number"
                value={selectedProviderPhoneNumber}
                onChange={(event) => setSelectedProviderPhoneNumber(event.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                style={{ marginLeft: '15px', width: '40%' }}
                name="username"
                label="Username"
                id="username"
                value={selectedProviderUsername}
                onChange={(event) => setSelectedProviderUsername(event.target.value)}

              />

              {renderClinics(selectedProviderClinic, setSelectedProviderClinic)}
              <p>{serverMessage2}</p>

            </form></Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setEditProvider(false)}>
                Close
              </Button>
              <Button onClick={deleteProvider}>Delete</Button>
              <Button variant="primary" onClick={saveProviderChanges}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
          
          <EditPatientModal
            canDelete={true}
            open={editPatient}
            patientId={selectedPatientID}
            patient={selectedPatient}
            canEditClinic={true}
            timezoneRequired={false}
            clinics={clinics}
            handleClose={() => setEditPatient(false)}
            savePatientChanges={savePatientChanges}
            deletePatient={deletePatient}
          />

          <AddCustomerModal
            opend={isAddCustomerModalOpend}
            onClose={closeAddCustomerModalhandler}
            onSave={() => addCustomerModalRef.current?.update()}
          />

          <AddDeviceModal
            opend={isAddDeviceModalOpend}
            onClose={closeAddDeviceModalhandler}
            onSave={() => addDeviceModalRef.current?.update()}
          />

          <Modal style={{ marginTop: '50px', marginLeft: '75px', width: '75%' }} show={open1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>Add Clinic</Modal.Title>
            </Modal.Header>
            <Modal.Body><form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                style={{ marginLeft: '15px', width: '40%' }}
                id="name"
                label="Practice Name"
                name="name"
                autoFocus
                onChange={(event) => setPracticeName(event.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                style={{ marginLeft: '15px', width: '40%' }}
                name="address"
                label="Street Address"
                id="address"
                onChange={(event) => setPracticeAddress(event.target.value)}
              />

              <TextField
                variant="outlined"
                margin="normal"
                required
                style={{ marginLeft: '15px', width: '40%' }}
                name="number"
                label="Phone Number"
                id="number"
                onChange={(event) => setPracticePhoneNumber(event.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                style={{ marginLeft: '15px', width: '40%' }}
                name="contact"
                label="Main Contact"
                id="contact"
                onChange={(event) => setPracticeMainContact(event.target.value)}
              />

              <div style={{ height: '20px' }}></div>
              <p>Add Logo:</p>
              <input type="file" accept="image/*" onChange={handleImageChange} />

              <p>{serverMessage4}</p>
            </form></Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose1}>
                Close
              </Button>
              <Button variant="primary" onClick={addClinic}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
          <AddProvider
            adminID={adminID} 
            clinics={clinics} 
            open={open2} 
            handleClose={handleClose2} 
            setProviders={setProviders}
          />
          <AddPatientModal
            adminID={adminID}
            clinics={clinics}
            open={open3}
            handleClose={handleClose3}
            setPatients={setPatients}
          />
          <Grid style={{ marginTop: '15px' }} container spacing={3}>
            {currentGrid === CLINICS_GRID ? (
              <Button style={{ backgroundColor: '#F0F0F0' }}>Clinics</Button>
            ) : (
              <Button onClick={() => setCurrentGrid(CLINICS_GRID)}>
                Clinics
              </Button>
            )}
            {currentGrid === CUSTOMERS_GRID ? (
              <Button style={{ backgroundColor: '#F0F0F0' }}>
                Customers
              </Button>
            ) : (
              <Button onClick={() => setCurrentGrid(CUSTOMERS_GRID)}>
                Customers
              </Button>
            )}
            {currentGrid === PROVIDERS_GRID ? (
              <Button style={{ backgroundColor: '#F0F0F0' }}>
                Providers
              </Button>
            ) : (
              <Button onClick={() => setCurrentGrid(PROVIDERS_GRID)}>
                Providers
              </Button>
            )}
            {currentGrid === PATIENTS_GRID ? (
              <Button style={{ backgroundColor: '#F0F0F0' }}>Patients</Button>
            ) : (
              <Button onClick={() => setCurrentGrid(PATIENTS_GRID)}>
                Patients
              </Button>
            )}
            {currentGrid === DEVICES_GRID ? (
              <Button style={{ backgroundColor: '#F0F0F0' }}>
                Devices
              </Button>
            ) : (
              <Button onClick={() => setCurrentGrid(DEVICES_GRID)}>
                Devices
              </Button>
            )}
          </Grid>
        </Container>
        {currentGrid === CLINICS_GRID  &&
          <Container maxWidth="lg" className={classes.container}>
            <Stack spacing={2}>
              <Box sx={{ alignItems: "start" }}>
                <Button variant="outlined" onClick={handleOpen1}>Add Clinic</Button>
              </Box>
              <Grid container spacing={3}>
                <Paper >
                  {clinicRows.length > 0 ? (
                    <table style={{ minWidth: '1000px' }} id="clinic-table" class="table stripe" ref={el => setEl(el)}>
                      <thead>
                        <tr>
                          <td>Name</td>
                          <td>Address</td>
                          <td>Phone Number</td>
                          <td>Contact</td>
                        </tr>
                      </thead>
                      <tbody>
                        {clinicRows.map((row, index) => (
                          <tr onClick={row.clickEvent}>
                            <td >{row.name}</td>
                            <td >{row.address}</td>
                            <td >{row.phoneNumber}</td>
                            <td >{row.mainContact}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <React.Fragment />
                  )}
                </Paper>
              </Grid>
            </Stack>
          </Container>}
        {currentGrid === PROVIDERS_GRID &&
          <Container maxWidth="lg" className={classes.container}>
            <Stack spacing={2}>
              <Box sx={{ alignItems: "start" }}>
                <Button variant="outlined" onClick={handleOpen2}>Add Provider</Button>
              </Box>
              <Grid container spacing={3}>
                <Paper >
                  <table style={{ minWidth: '1000px' }} id="provider-table" class="table stripe" ref={el2 => setEl2(el2)}>
                    <thead>
                      <tr>
                        <td>Username</td>
                        <td>First Name</td>
                        <td>Last Name</td>
                        <td>Phone Number</td>
                        <td>Email</td>
                        <td>Clinic</td>
                      </tr>
                    </thead>
                    <tbody>
                      {providerRows.map((row, index) => (
                        <tr onClick={row.clickEvent}>
                          <td >{row.username}</td>
                          <td >{row.firstName}</td>
                          <td >{row.lastName}</td>
                          <td >{row.phoneNumber}</td>
                          <td >{row.email}</td>
                          <td >{row.clinic}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Paper>
              </Grid>
            </Stack>
          </Container>}
        {currentGrid === PATIENTS_GRID &&
          <Container maxWidth="lg" className={classes.container}>
            <Stack spacing={2}>
              <Box sx={{ alignItems: "start" }}>
                <Button variant="outlined" onClick={handleOpen3}>Add Patient</Button>
              </Box>
              <Grid container spacing={3}>
                <Paper >
                  <table style={{ minWidth: '1000px' }} id="patient-table" class="table stripe" ref={el3 => setEl3(el3)}>
                    <thead>
                      <tr>
                        <td>First Name</td>
                        <td>Last Name</td>
                        <td>Phone Number</td>
                        <td>Cell Number</td>
                        <td>Email</td>
                        <td>City</td>
                        <td>State</td>
                        <td>Time Zone</td>
                        <td>BP IMEI</td>
                        <td>BP Manufacter</td>
                        <td>Weight IMEI</td>
                        <td>Clinic</td>
                        <td>SMS Consent</td>
                      </tr>
                    </thead>
                    <tbody>
                      {patientRows.map((row, index) => (
                        <tr key={index} onClick={row.clickEvent}>
                          <td >{row.firstName}</td>
                          <td >{row.lastName}</td>
                          <td >{row.phoneNumber}</td>
                          <td >{row.cellNumber}</td>
                          <td >{row.email}</td>
                          <td >{row.city}</td>
                          <td >{row.state}</td>
                          <td >{row.timeZone}</td>
                          <td >{row.selectedBpIMEI}</td>
                          <td >{row.selectedBpDevice}</td>
                          <td >{row.selectedWeightIMEI}</td>
                          <td >{row.clinic}</td>
                          <td>{!!row.deviceNotificationsEnabled ? <Check /> : <NotInterested />}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Paper>
              </Grid>
            </Stack>
          </Container>}
          {currentGrid === CUSTOMERS_GRID && (
            <Container maxWidth="lg" className={classes.container}>
              <Stack spacing={2}>
                <Box sx={{ alignItems: "start" }}>
                  <Button variant="outlined" onClick={openAddCustomerModalhandler}>Add Customer</Button>
                </Box>
                <CustomersGrid ref={addCustomerModalRef} />
              </Stack>
            </Container>
          )}
          {currentGrid === DEVICES_GRID && (
            <Container maxWidth="lg" className={classes.container}>
              <Stack spacing={2}>
                <Box sx={{ alignItems: "start" }}>
                  <Button variant="outlined" onClick={openAddDeviceModalhandler}>Add Device</Button>
                </Box>
                <DevicesGrid ref={addDeviceModalRef} />
              </Stack>
            </Container>
          )}
      </main>}
      {registerDevicesDashboard && <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <RegisterWithingsDevices patients={patients} adminId={adminID} />
        </Container>
      </main>}
    </div>
  );
}