export const registerDevice = (input) =>
  fetch('/routes/withings/activate', {
    method: 'POST',
    body: JSON.stringify(input),
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      return { error };
    });
