import moment from 'moment-timezone';

export const parseGlucoseMeasures = ({ glucose = [], timeframe = 0 }) => {
  const glucoseChartArray = [];
  const glucoseVals = [];
  const glucoseTableArray = [];
  let averageGlucose = '-';

  // Parse Glucose Chart values
  for (let z = 0; z < glucose.length; z++) {
    const tempDate = new Date(glucose[z].ts * 1000);
    if (tempDate >= timeframe) {
      glucoseVals.push(glucose[z].data);
      glucoseChartArray.push(glucose[z]);
    }
  }

  // get average glucose value
  if (glucoseVals.length) {
    const sumGlucose = glucoseVals.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    averageGlucose = Math.round(sumGlucose / glucoseVals.length);
  }

  // parse Gluocse chart array values into table values
  for (let u = 0; u < glucoseChartArray.length; u++) {
    // glucose unix timestamp is in seconds
    const glucoseObj = {
      date: new Date(glucoseChartArray[u].ts * 1000),
      time: moment.unix(glucoseChartArray[u].ts).format('h:mm A'),
      data: glucoseChartArray[u].data,
    };
    glucoseTableArray.push(glucoseObj);
  }

  return { glucoseChartArray, glucoseTableArray, averageGlucose };
};
