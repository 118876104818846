import moment from 'moment-timezone';
import { pascalsToTorr } from '../../../common/conversions';
import { TRANSTEK, WITHINGS } from '../../../common/manufacters';

const parseTranstekBpm = (bpm) => {
  const systolic = parseInt(bpm.systolic);
  const diastolic = parseInt(bpm.diastolic);
  const ts = bpm.ts * 1000;
  const pulse = bpm.pulse;

  return {
    systolic,
    diastolic,
    ts,
    pulse,
  };
};

const parseWithingsBpm = (bpm) => {
  const systolic = bpm.sys.value * Math.pow(10, bpm.sys.unit);
  const diastolic = bpm.dia.value * Math.pow(10, bpm.dia.unit);
  const pulse = bpm.pulse.value * Math.pow(10, bpm.pulse.unit);
  const ts = bpm.created * 1000;

  return {
    systolic,
    diastolic,
    ts,
    pulse,
  };
};

const parseBodyTraceBpm = (bpm) => {
  const systolic = pascalsToTorr(bpm.systolic);
  const diastolic = pascalsToTorr(bpm.diastolic);
  const ts = bpm._created_at;
  const pulse = bpm.pulse;

  return {
    systolic,
    diastolic,
    ts,
    pulse,
  };
};

const parseBodyTraceMessageBpm = (bpm) => {
  const systolic = pascalsToTorr(bpm.message?.values?.systolic);
  const diastolic = pascalsToTorr(bpm.message?.values?.diastolic);
  const pulse = bpm.message?.values?.pulse;
  const ts = bpm.createdAt;

  return {
    systolic,
    diastolic,
    pulse,
    ts,
  };
};

export const parseBpMeasures = ({
  bpm = [],
  btMessagesBpm = [],
  ttBpm = [],
  withingsBpm = [],
  bpDevice = '',
  timeframe = 0,
  timeZone,
}) => {
  const arrayBP = [];
  const bpmTableArray = [];

  let lastReading = {};
  let avgSys = 0;
  let avgDia = 0;
  let highSys = '';
  let highDia = '';
  let lowSys = '';
  let lowDia = '';

  // Blood Pressure Chart Array
  if (bpDevice === TRANSTEK) {
    // Transtek BP Device
    for (let z = 0; z < ttBpm.length; z++) {
      const tempDate = new Date(ttBpm[z].ts * 1000);
      if (tempDate >= timeframe) {
        const { systolic, diastolic, ts, pulse } = parseTranstekBpm(ttBpm[z]);
        arrayBP.push({
          ts,
          systolic,
          diastolic,
          pulse,
        });
        avgSys += systolic;
        avgDia += diastolic;
      }
    }
    if (ttBpm.length > 0) {
      lastReading = parseTranstekBpm(ttBpm[0]);
    }
  } else if (bpDevice === WITHINGS) {
    // Withings BP Device
    for (let z = 0; z < withingsBpm.length; z++) {
      const measurement = withingsBpm[z];
      const tempDate = new Date(measurement.created * 1000);
      if (tempDate >= timeframe) {
        const { systolic, diastolic, ts, pulse } =
          parseWithingsBpm(measurement);
        arrayBP.push({
          ts,
          systolic,
          diastolic,
          pulse,
        });
        avgSys += systolic;
        avgDia += diastolic;
      }
    }
    if (withingsBpm.length > 0) {
      lastReading = parseWithingsBpm(withingsBpm[0]);
    }
  } else {
    // Bodytrace BP Device
    for (let z = 0; z < bpm.length; z++) {
      const tempDate = new Date(bpm[z]._created_at);
      if (tempDate >= timeframe) {
        const { systolic, diastolic, ts, pulse } = parseBodyTraceBpm(bpm[z]);
        arrayBP.push({
          ts,
          systolic,
          diastolic,
          pulse,
        });
        avgSys += systolic;
        avgDia += diastolic;
      }
    }
    // bodytracemessages BP readings
    for (let z = 0; z < btMessagesBpm.length; z++) {
      const reading = btMessagesBpm[z];
      const tempDate = new Date(reading.createdAt);
      if (tempDate >= timeframe) {
        const { systolic, diastolic, ts, pulse } =
          parseBodyTraceMessageBpm(reading);
        arrayBP.push({
          ts,
          systolic,
          diastolic,
          pulse,
        });
        avgSys += systolic;
        avgDia += diastolic;
      }
    }
    // sort arrayBP
    if (btMessagesBpm.length && bpm.length) {
      arrayBP.sort((a, b) => {
        if (a.ts < b.ts) {
          return 1;
        }
        if (a.ts > b.ts) {
          return -1;
        }
        return 0;
      });
    }
    const recentBtMeasure = bpm?.[0];
    const recentBtMessage = btMessagesBpm?.[0];
    if (recentBtMeasure && recentBtMessage) {
      lastReading =
        recentBtMeasure.ts > recentBtMessage.ts
          ? parseBodyTraceBpm(recentBtMeasure)
          : parseBodyTraceMessageBpm(recentBtMessage);
    } else if (recentBtMeasure) {
      lastReading = parseBodyTraceBpm(recentBtMeasure);
    } else if (recentBtMessage) {
      lastReading = parseBodyTraceMessageBpm(recentBtMessage);
    }
  }

  // calculate Blood Pressure average, max, and low
  if (arrayBP.length > 0) {
    avgSys = Math.round(avgSys / arrayBP.length);
    avgDia = Math.round(avgDia / arrayBP.length);
    highSys = arrayBP[0].systolic;
    highDia = arrayBP[0].diastolic;
    lowSys = arrayBP[0].systolic;
    lowDia = arrayBP[0].diastolic;
    for (let c = 0; c < arrayBP.length; c++) {
      if (highSys < arrayBP[c].systolic) {
        highSys = arrayBP[c].systolic;
        highDia = arrayBP[c].diastolic;
      }
      if (lowSys > arrayBP[c].systolic) {
        lowSys = arrayBP[c].systolic;
        lowDia = arrayBP[c].diastolic;
      }
    }
  } else {
    highSys = '';
    highDia = '';
    lowSys = '';
    lowDia = '';
    avgSys = '';
    avgDia = '';
  }

  // parse Blood Pressure Chart array values to table array
  for (let r = 0; r < arrayBP.length; r++) {
    let tableDate = '';
    let tableTime = '';
    if (moment(arrayBP[r].ts).tz(timeZone)) {
      tableDate = moment(arrayBP[r].ts).tz(timeZone).toDate();
      tableTime = moment(arrayBP[r].ts).tz(timeZone).format('h:mm A');
    }

    const tableObj = {
      date: tableDate,
      time: tableTime,
      systolic: arrayBP[r].systolic,
      diastolic: arrayBP[r].diastolic,
      pulse: arrayBP[r].pulse,
    };
    bpmTableArray.push(tableObj);
  }

  return {
    arrayBP,
    bpmTableArray,
    highSys,
    highDia,
    lowSys,
    lowDia,
    avgSys,
    avgDia,
    lastReading,
  };
};
