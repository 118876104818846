import { useId, useEffect, useState, useRef } from 'react';
import Button from '@mui/material/Button';
import { Dialog } from '../components/common/Dialog';
import { useForm, FormProvider } from 'react-hook-form';
import { DeviceForm } from './DeviceForm';
import { getCustomers } from '../customer/getCustomers';
import { updateDevice } from './updateDevice';
import { getOneDevice } from './getOneDevice';

const useUpdateEffect = (handler, deps) => {
  const isMoutedRef = useRef(null);
  useEffect(() => {
    if (!isMoutedRef.current) {
      isMoutedRef.current = true;
    } else {
      return handler();
    }
  }, deps);
};

const DEFAULT_FORM_STATE = {
  identifier: '',
  type: 'bpm_gen2_status',
  customer: null,
  endpoint: null,
  clinic: null,
  billingStart: null,
  billingEnd: null,
  manufacturer: null,
};

const getClinicsByCustomerId = (customers, id) => {
  const customer = customers.find(({ _id }) => _id === id);
  return customer?.clinics || [];
};

const getEndpointsByCustomerId = (customers, id) => {
  const customer = customers.find(({ _id }) => _id === id);
  return customer?.endpoints || [];
};

export const EditDeviceModal = ({ id, opend, onClose = () => {}, onSave }) => {
  const formId = useId();
  const [serverError, setServerError] = useState('');
  const [isCustomersLoaded, setCustomersLoaded] = useState(false);

  const [customers, setCustomers] = useState([]);
  const [clinics, setClinics] = useState([]);
  const [endpoints, setEndpoints] = useState([]);

  const methods = useForm({
    defaultValues: DEFAULT_FORM_STATE,
  });

  useEffect(() => {
    if (opend) {
      Promise.all([getCustomers(), getOneDevice(id)]).then(
        ([{ customers }, device]) => {
          setCustomers(customers);

          if (device.imei) {
            methods.setValue('identifier', device.imei);
          }
          if (device.deviceId) {
            methods.setValue('identifier', device.deviceId);
          }
          methods.setValue('type', device.type);
          methods.setValue('manufacturer', device.manufacturer);
          methods.setValue('customer', device.customer);
          methods.setValue('clinic', device.clinic);
          methods.setValue('endpoint', device.endpoint);
          methods.setValue('billingStart', device.billingStart);
          methods.setValue('billingEnd', device.billingEnd);

          setCustomersLoaded(true);
        }
      );
    }
  }, [opend, id]);

  const customerId = methods.watch('customer');

  useUpdateEffect(() => {
    setClinics(getClinicsByCustomerId(customers, customerId));
    setEndpoints(getEndpointsByCustomerId(customers, customerId));
  }, [customerId]);

  const closeHandler = () => {
    onClose?.();
    methods.reset();
    setCustomersLoaded(false);
    setServerError('');
  };

  const onSubmit = async (request) => {
    try {
      await updateDevice(id, request);
      onSave?.();
      closeHandler();
    } catch (e) {
      setServerError(e.toString());
    }
  };

  return (
    <Dialog
      open={opend}
      title="Edit Device"
      handleClose={closeHandler}
      actions={
        <>
          <Button variant="secondary" onClick={closeHandler}>
            Close
          </Button>
          <Button
            disabled={!isCustomersLoaded || methods.formState.isSubmitting}
            form={formId}
            type="submit"
            variant="primary"
          >
            Submit
          </Button>
        </>
      }
    >
      <FormProvider {...methods}>
        <form id={formId} noValidate onSubmit={methods.handleSubmit(onSubmit)}>
          <DeviceForm
            serverError={serverError}
            isLoading={!isCustomersLoaded}
            customers={customers}
            clinics={clinics}
            endpoints={endpoints}
          />
        </form>
      </FormProvider>
    </Dialog>
  );
};
