export const createDevice = async (createDeviceRequest) => {
  const response = await fetch('/routes/devices', {
    withCredentials: true,
    method: 'POST',
    body: JSON.stringify(createDeviceRequest),
    headers: { 'Content-Type': 'application/json' },
  });

  if (response.ok) {
    return response.json();
  } else {
    const { message } = await response.json();
    throw Error(message || 'Unknown error');
  }
};
