import { useState, useEffect, useCallback } from 'react';
import { useEventEmitter } from 'ahooks';
import { getCustomers } from "../getCustomers"


const convertSortModelToQueryParam = (sortModel) =>
  sortModel.map(({ field, sort }) => `${field}:${sort}`);

const convertFilterModelToQueryParams = (filterModel) => {
  if (!Boolean(filterModel) || filterModel?.items?.length === 0) {
    return {};
  }
  const { field, operator, value } = filterModel.items[0];
  return {
    filterProps: `${field}:${operator}`,
    filterValue: value,
  };
};

export const useCustomersData = (
  { page, pageSize },
  sortModel,
  filterModel
) => {
  const [rows, setRows] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState();
  const [rowCountState, setRowCountState] = useState(
    pageInfo?.totalRowCount || 0
  );

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      pageInfo?.totalRowCount !== undefined
        ? pageInfo?.totalRowCount
        : prevRowCountState
    );
  }, [pageInfo?.totalRowCount, setRowCountState]);

  const handleGetCustomers = () =>
    getCustomers({
      page,
      pageSize,
      sort: convertSortModelToQueryParam(sortModel || []),
      ...convertFilterModelToQueryParams(filterModel),
    })
      .then(({ customers, pageInfo }) => {
        setRows(customers);
        setPageInfo(pageInfo);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });

  useEffect(() => {
    setLoading(true);
    handleGetCustomers();
  }, [page, pageSize, sortModel, filterModel]);

  const dataBus = useEventEmitter();
  dataBus.useSubscription((val) => {
    handleGetCustomers();
  });

  return {
    rows,
    isLoading: isLoading,
    rowCountState,
    dataBus,
  };
};
