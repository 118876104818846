import {
  useState,
  useCallback,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useCustomersData } from './useCustomersData';
import Box from '@mui/material/Box';
import { EditCustomerModal } from '../EditCustomerModal';
import { DeleteCustomerDialog } from '../DeleteCustomerDialog';
import { CustomerDetailsModal } from '../CustomerDetailsModal/CustomerDetailsModal';
import Link from '@mui/material/Link';

const PAGE_SIZE = 10;
const getRowId = (row) => row._id;

const RowActions = ({ id, onDelete, onEdit }) => (
  <Box>
    <IconButton onClick={() => onDelete(id)}>
      <DeleteIcon />
    </IconButton>
    <IconButton onClick={() => onEdit(id)}>
      <EditIcon />
    </IconButton>
  </Box>
);

const Toolbar = () => (
  <GridToolbarContainer>
    <GridToolbarFilterButton variant="primary" />
  </GridToolbarContainer>
);

const commonColumnProps = {
  flex: 1,
  aggregable: false,
  groupable: false,
  resizable: false,
};

const createColumns = ({ onDelete, onEdit, onShowDetails }) => [
  {
    ...commonColumnProps,
    field: 'name',
    headerName: 'Name',
    type: 'string',
    hideable: false,
    renderCell: (params) => (
      <Link
        component="button"
        variant="body2"
        onClick={() => onShowDetails(params.id)}
      >
        {params.value}
      </Link>
    ),
  },
  {
    ...commonColumnProps,
    field: 'contact',
    headerName: 'Contact',
    type: 'string',
  },
  {
    ...commonColumnProps,
    field: 'contactNumber',
    headerName: 'Contact Number',
    type: 'string',
  },
  {
    ...commonColumnProps,
    field: 'email',
    headerName: 'Email',
    type: 'string',
  },
  {
    ...commonColumnProps,
    field: 'address',
    headerName: 'Address',
    type: 'string',
  },
  {
    ...commonColumnProps,
    field: 'clinics',
    headerName: 'Clinics',
    type: 'string',
    valueFormatter: ({ value }) => {
      return value.map(({ name }) => name).join(', ');
    },
  },
  {
    ...commonColumnProps,
    field: 'actions',
    headerName: 'Actions',
    headerAlign: 'center',
    sortable: false,
    flex: 0,
    filterable: false,
    hideable: false,
    renderCell: (params) => {
      return <RowActions id={params.id} onDelete={onDelete} onEdit={onEdit} />;
    },
  },
];

export const CustomersGrid = forwardRef((props, ref) => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const [curentEditCustomerId, setCurentEditCustomerId] = useState(null);
  const [curentDeleteCustomerId, setCurentDeleteCustomerId] = useState(null);
  const [curentDetailsId, setCurentDetailsId] = useState(null);
  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState();

  const deleteHandler = useCallback((id) => setCurentDeleteCustomerId(id), []);
  const editHandler = useCallback((id) => setCurentEditCustomerId(id), []);
  const showDetailsHandler = useCallback((id) => setCurentDetailsId(id), []);

  const { isLoading, rows, rowCountState, dataBus } = useCustomersData(
    paginationModel,
    sortModel,
    filterModel
  );

  const columns = useMemo(
    () =>
      createColumns({
        onEdit: editHandler,
        onDelete: deleteHandler,
        onShowDetails: showDetailsHandler,
      }),
    []
  );

  useImperativeHandle(
    ref,
    () => {
      return {
        update() {
          dataBus.emit('update');
        },
      };
    },
    [dataBus]
  );

  return (
    <>
      <DataGrid
        slots={{
          toolbar: Toolbar,
        }}
        sx={{
          '& p': {
            mt: '1em',
            mb: '1em',
          },
          '--DataGrid-overlayHeight': '300px',
        }} // rewrite boostrap styles.
        getRowId={getRowId}
        columns={columns}
        rows={rows || undefined}
        rowCount={rowCountState}
        loading={isLoading}
        pageSizeOptions={[PAGE_SIZE]}
        paginationModel={paginationModel}
        paginationMode="server"
        sortingMode="server"
        filterMode="server"
        onPaginationModelChange={setPaginationModel}
        onSortModelChange={setSortModel}
        onFilterModelChange={setFilterModel}
        disableRowSelectionOnClick
        autoHeight
      />
      <EditCustomerModal
        opend={Boolean(curentEditCustomerId)}
        id={curentEditCustomerId}
        onClose={() => setCurentEditCustomerId(null)}
        onSave={() => dataBus.emit('update')}
      />
      <DeleteCustomerDialog
        opend={Boolean(curentDeleteCustomerId)}
        id={curentDeleteCustomerId}
        onClose={() => setCurentDeleteCustomerId(null)}
        onDelete={() => dataBus.emit('update')}
      />

      <CustomerDetailsModal
        opend={Boolean(curentDetailsId)}
        id={curentDetailsId}
        onClose={() => setCurentDetailsId(null)}
        onEdit={() => {
          setCurentDetailsId(null)
          setCurentEditCustomerId(curentDetailsId)
        }}
      />
    </>
  );
});
