import { Fragment, useState, useMemo } from 'react';
import { Container, Paper, Grid, Button } from '@material-ui/core';
import moment from 'moment-timezone';
import clsx from 'clsx';
import { useStyles } from '../common/style';
import {
  BloodPressureChart,
  HeartRateChart,
} from '../PatientData/BloodPressure/BloodPressureCharts';
import { BloodPressureGrid } from '../PatientData/BloodPressure/BloodPressureGrid';
import {
  DurationButtons,
  useDurationDays,
} from '../PatientData/DurationButtons';
import { InfoDropdown } from '../PatientData/Dropdown';
import { parseDeviceMeasures } from './parseDeviceMeasures';

const InfoGrid = ({ info, title, subText }) => {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <Grid item xs={6} md={3} lg={3}>
      <Paper className={fixedHeightPaper}>
        <Container>
          <h4 style={{ textAlign: 'center', marginTop: '40px' }}>{info}</h4>
          <h5 style={{ textAlign: 'center' }}>{title}</h5>
          <p style={{ textAlign: 'center', marginTop: '60px' }}>{subText}</p>
        </Container>
      </Paper>
    </Grid>
  );
};

const lastReadingDate = (ts, timeZone) => {
  const lastReading = moment(ts);
  if (!ts || !lastReading?.isValid()) {
    return '';
  }
  const timezone =
    timeZone && timeZone !== 'local' ? timeZone : moment.tz.guess();
  if (lastReading.isBefore(moment().subtract(3, 'd'))) {
    return lastReading.tz(timezone).format('MM/DD/YY h:mm A');
  } else if (lastReading.isBefore(moment().subtract(2, 'd'))) {
    return 'Two Days Ago';
  } else if (lastReading.isBefore(moment().subtract(1, 'd'))) {
    return 'Yesterday';
  } else {
    return 'Today';
  }
};

export const DeviceReadingsDisplay = ({ imei, deviceData }) => {
  const [tableView, setTableView] = useState('Chart');
  const { days, durationText, setDurationText, setDays } = useDurationDays();

  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const timeframe = useMemo(
    () => moment().subtract(days, 'days').toDate(),
    [days]
  );

  const {
    arrayBP,
    bpmTableArray,
    avgSys,
    avgDia,
    timeZone,
    deviceInfo: { sn, iccid, dataType, modelNumber, deviceId },
    lastReading,
  } = useMemo(
    () => parseDeviceMeasures(deviceData, timeframe),
    [deviceData, timeframe]
  );

  return (
    <Fragment>
      <Container maxWidth="lg" className={classes.container}>
        <h2>
          Blood Pressure Measurements
          <InfoDropdown
            items={[
              ...(imei ? [`IMEI: ${imei}`] : []),
              ...(sn ? [`Serial Number: ${sn}`] : []),
              ...(iccid ? [`ICCID: ${iccid}`] : []),
              ...(dataType ? [`Data Type: ${dataType}`] : []),
              ...(modelNumber ? [`Model Number: ${modelNumber}`] : []),
              ...(deviceId ? [`Device Id: ${deviceId}`] : []),
            ]}
          />
        </h2>
        <Grid container spacing={3}>
          {lastReading.ts ? (
            <InfoGrid
              info={`${lastReading.systolic}/${lastReading.diastolic}`}
              title={'Last Measurement'}
              subText={lastReadingDate(lastReading.ts, timeZone)}
            />
          ) : (
            <InfoGrid
              info={''}
              title={''}
              subText={'No measurements have been recorded yet'}
            />
          )}
          <InfoGrid
            info={`${avgSys}/${avgDia}`}
            title={'Avg BP'}
            subText={durationText}
          />
        </Grid>
        <div style={{ float: 'left', marginTop: '5px' }}>
          {tableView === 'Chart' ? (
            <div>
              <Button style={{ backgroundColor: '#F0F0F0' }}>Chart</Button>
              <Button onClick={() => setTableView('Readings')}>Readings</Button>
            </div>
          ) : (
            <div>
              <Button onClick={() => setTableView('Chart')}>Chart</Button>
              <Button style={{ backgroundColor: '#F0F0F0' }}>Readings</Button>
            </div>
          )}
        </div>
        <DurationButtons
          days={days}
          setDays={setDays}
          setDurationText={setDurationText}
        />
      </Container>
      {tableView === 'Chart' ? (
        <Container maxWidth="lg" className={classes.container}>
          <Grid item xs={12} lg={12} className={classes.container}>
            <Paper className={fixedHeightPaper}>
              <BloodPressureChart timeZone={timeZone} chartData={arrayBP} />
            </Paper>
          </Grid>
          <Grid item xs={12} lg={12} className={classes.container}>
            <Paper className={fixedHeightPaper}>
              <HeartRateChart timeZone={timeZone} chartData={arrayBP} />
            </Paper>
          </Grid>
        </Container>
      ) : (
        <Container maxWidth="lg" className={classes.container}>
          <Grid item xs={12} lg={12} className={classes.container}>
            <Paper>
              <BloodPressureGrid gridData={bpmTableArray} />
            </Paper>
          </Grid>
        </Container>
      )}
    </Fragment>
  );
};
