export const getDeviceStatus = async (imei) => {
  return fetch(`/routes/device-updates/status/${imei}`, {
    withCredentials: true,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  }).then((response) => {
    if (response.ok || response.status === 404) {
      return response.json();
    }
    return {
      error: 'server-error',
      message: 'There is an error with the IMEI number.',
    };
  });
};
